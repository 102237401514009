import { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ChevronIcon from "../icons/Chevron";
import { Link } from "react-router-dom";
import { MobileBreakpoint } from "../../config/constants";

const BreadcrumbsStyled = styled.div`
  display: flex;
  margin: 20px 0;

  @media screen and (max-width: ${MobileBreakpoint}) {
    display: none;
  }
`;

const BreadcrumbsContainer = styled.div`
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1600px;
  display: flex;
  gap: 5px;
  width: 100%;
`;

const Breadcrumbs = ({ breadcrumbs, separator = <ChevronIcon sx={{ width: 12, fill: "#575757" }} /> }) => {
  return (
    <BreadcrumbsStyled>
      <BreadcrumbsContainer>
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={breadcrumb.uuid}>
            {index > 0 && index <= breadcrumbs.length - 1 && separator}
            {breadcrumb.url && <Link to={breadcrumb.url}>{breadcrumb.title}</Link>}
            {!breadcrumb.url && <span className="lighter-text">{breadcrumb.title}</span>}
          </Fragment>
        ))}
      </BreadcrumbsContainer>
    </BreadcrumbsStyled>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired, // Specifies that breadcrumbs must be an array and is required
  separator: PropTypes.oneOfType([PropTypes.node, PropTypes.string]), // Specifies that separator must be a React node or string
};

export default Breadcrumbs;
