// StarIcon

import PropTypes from 'prop-types';

const variantPathData = {
    empty:
        'M50,23.4l9.3,18.9l20.8,3L65,59.9l3.6,20.7L50,70.9l-18.6,9.8L35,59.9l-15-14.7l20.8-3L50,23.4 M50,2.4l-8.3,16.9l-7.1,14.5l-16,2.3L0,38.8l13.4,13.1L25,63.2l-2.7,15.9l-3.2,18.5l16.6-8.7L50,81.3l14.3,7.5l16.6,8.7l-3.2-18.5L75,63.2l11.6-11.3L100,38.8l-18.6-2.7l-16-2.3l-7.1-14.5L50,2.4z',
    full:
        'M86.6,51.9L75,63.2L77.7,79l3.2,18.5l-16.6-8.7L50,81.3l-14.3,7.6l-16.6,8.7l3.2-18.5L25,63.2L13.4,51.9L0,38.8l18.6-2.7l16-2.3l7.1-14.5L50,2.4l8.3,16.9l7.1,14.5l16,2.3l18.6,2.7L86.6,51.9z',
    half:
        'M50,23.4l9.3,18.9l20.8,3L65,59.9l3.6,20.7L50,70.9V23.4 M50,2.4l-8.3,16.9l-7.1,14.5l-16,2.3L0,38.8l13.4,13.1L25,63.2l-2.7,15.9l-3.2,18.5l16.6-8.7L50,81.3l14.3,7.5l16.6,8.7L77.7,79L75,63.2l11.6-11.3L100,38.8l-18.6-2.7l-16-2.3l-7.1-14.5L50,2.4z',
};

const StarIcon = ({ sx, variant = 'empty', ...restProps }) => {
    const defaultSize = 24;
    const pathData = variantPathData[variant];

    return (
        <svg
            {...restProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }}
        >
            <path d={pathData} />
        </svg>
    );
};

StarIcon.propTypes = {
    sx: PropTypes.object,
    variant: PropTypes.oneOf(['empty', 'full', 'half']),
};

export default StarIcon;

