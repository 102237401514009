import PropTypes from 'prop-types';

const YouTubeIconProps = {
    sx: PropTypes.object,
};

const YouTubeIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M93.1,28.3c-1-3.9-4.1-6.9-8-8c-7-1.9-35.2-1.9-35.2-1.9s-28.1,0-35.2,1.9c-3.9,1-6.9,4.1-8,8C5,35.4,5,50,5,50s0,14.6,1.9,21.7c1,3.9,4.1,6.9,8,8c7,1.9,35.2,1.9,35.2,1.9s28.1,0,35.2-1.9c3.9-1,6.9-4.1,8-8C95,64.6,95,50,95,50S95,35.4,93.1,28.3z M41,63.5v-27L64.4,50L41,63.5z' />
        </svg>
    );
};

YouTubeIcon.propTypes = YouTubeIconProps;

export default YouTubeIcon;

