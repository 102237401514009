// providers/CartContext.js
import React, { createContext, useContext, useReducer, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

const ADD_ITEM = "ADD_ITEM";
const REMOVE_ITEM = "REMOVE_ITEM";
const UPDATE_QUANTITY = "UPDATE_QUANTITY";
const CLEAR_CART = "CLEAR_CART";

const initialCartState = {
  items: [],
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
      };
    case UPDATE_QUANTITY:
      return {
        ...state,
        items: action.payload,
      };
    case CLEAR_CART:
      return {
        ...state,
        items: [],
      };
    case "LOAD_CART":
      return action.payload;
    default:
      return state;
  }
};

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

const CartProviderProps = {
  children: PropTypes.node,
};

export const CartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(cartReducer, initialCartState, () => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : initialCartState;
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartState));
  }, [cartState]);

  const addToCart = useCallback(
    (product, addToQuantity) => {
      const existingItemIndex = cartState.items.findIndex(item => item.id === product.id);

      const addToQuantityParsed = parseInt(addToQuantity);

      if (existingItemIndex !== -1) {
        // Product already exists in the cart, increase quantity by the specified amount
        const updatedItems = [...cartState.items];
        updatedItems[existingItemIndex].quantity += addToQuantityParsed; // Add to existing quantity

        dispatch({
          type: UPDATE_QUANTITY,
          payload: updatedItems, // Pass the updated items array
        });
      } else {
        // Product does not exist in the cart, add it with the specified quantity
        dispatch({
          type: ADD_ITEM,
          payload: { ...product, quantity: addToQuantityParsed }, // Add quantity property
        });
      }
    },
    [cartState]
  );

  const removeFromCart = productId => {
    dispatch({
      type: REMOVE_ITEM,
      payload: productId,
    });
  };

  const updateProductQuantity = useCallback(
    (productId, quantity) => {
      const updatedItems = cartState.items.map(item => {
        if (item.id === productId) {
          return { ...item, quantity: parseInt(quantity) };
        }
        return item;
      });

      dispatch({
        type: "UPDATE_QUANTITY",
        payload: updatedItems,
      });
    },
    [cartState]
  );

  const clearCart = () => {
    dispatch({
      type: CLEAR_CART,
    });
  };

  const CartContextValue = useMemo(
    () => ({
      cart: cartState,
      addToCart,
      removeFromCart,
      updateProductQuantity,
      clearCart,
    }),
    [cartState, addToCart, updateProductQuantity]
  );

  return <CartContext.Provider value={CartContextValue}>{children}</CartContext.Provider>;
};

CartProvider.propTypes = CartProviderProps;
