// Images
import ToiletriesBag from "./static/img/products/ecofriendly_toiletriesbag.jpeg";
import LeatherPassportHolder from "./static/img/products/leather_passportholder.jpeg";
import FaceMask from "./static/img/products/aa_logo_mask.jpeg";
import LeatherCharger from "./static/img/products/leather_charger.jpeg";
import AdventureStickerSet from "./static/img/products/adveture_sticker_set1.jpeg";
import AdventureStickerSet2 from "./static/img/products/adveture_sticker_set2.jpeg";
import SolarCharger from "./static/img/products/solar_charger.jpeg";
import CompassNecklace from "./static/img/products/compass_necklace.jpeg";
import ExploreBottle from "./static/img/products/explore_bottle.jpeg";
import ExploreBottle2 from "./static/img/products/explore_bottle_2.jpeg";
import ExploreBottle3 from "./static/img/products/explore_bottle_3.jpeg";
import ExploreSticker from "./static/img/products/explore_bonussticker.jpeg";
import SmallTravelWallet from "./static/img/products/small_travel_wallet.jpeg";

export const products = [
  {
    id: 1111,
    source: ToiletriesBag,
    alt: "Eco-Friendly Toiletries Bag",
    title: "Eco-Friendly Toiletries Bag",
    price: "$19.99",
    reviews: [],
    stock: 100,
    sku: "Eco-Friendly Toiletries Bag",
  },
  {
    id: 1112,
    source: LeatherPassportHolder,
    alt: "Leather Passport Holder",
    title: "Leather Passport Holder",
    price: "$19.99",
    // reviews: [
    //     {
    //         review: 'First review. Woohoo!',
    //         name: 'John Doe'
    //     },
    // ],
    reviews: [],
    stock: 100,
    sku: "Leather Passport Holder",
  },
  {
    id: 1113,
    source: FaceMask,
    alt: "Face Mask",
    title: "Face Mask",
    price: "$9.99",
    reviews: [],
    stock: 100,
    sku: "Face Mask",
  },
  {
    id: 1114,
    source: LeatherCharger,
    alt: "Leather Charger",
    title: "Leather Charger",
    price: "$24.99",
    reviews: [],
    stock: 100,
    sku: "Leather Charger",
  },
  {
    id: 1115,
    source: AdventureStickerSet,
    variants: [{ source: AdventureStickerSet2 }],
    alt: "Adventure Sticker Set",
    title: "Adventure Sticker Set",
    price: "$14.99",
    reviews: [],
    stock: 100,
    sku: "Adventure Sticker Set",
  },
  {
    id: 1116,
    source: SolarCharger,
    alt: "Solar Charger",
    title: "Solar Charger",
    price: "$29.99",
    reviews: [],
    stock: 100,
    sku: "Solar Charger",
  },
  {
    id: 1117,
    source: CompassNecklace,
    alt: "Compass Necklace",
    title: "Compass Necklace",
    price: "$24.99",
    reviews: [],
    stock: 100,
    sku: "Compass Necklace",
  },
  {
    id: 1118,
    source: ExploreBottle,
    variants: [{ source: ExploreBottle2 }, { source: ExploreBottle3 }],
    alt: "Explore Bottle",
    title: "Explore Bottle",
    price: "$14.99",
    reviews: [],
    stock: 100,
    sku: "Explore Bottle",
  },
  {
    id: 1119,
    source: ExploreSticker,
    alt: "Explore Sticker",
    title: "Explore Sticker",
    price: "$1.99",
    reviews: [],
    stock: 100,
    sku: "Explore Sticker",
  },
  {
    id: 1120,
    source: SmallTravelWallet,
    alt: "Small Travel Wallet",
    title: "Small Travel Wallet",
    price: "$9.99",
    reviews: [],
    stock: 100,
    sku: "Small Travel Wallet",
  },
];

export function getProduct(id) {
  for (let product of products) {
    if (product.id === id) {
      return product;
    }
  }
}
