import PropTypes from 'prop-types';

const PinIconProps = {
    sx: PropTypes.object,
};

const PinIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M78.2,35.7C78.2,51.2,53.9,95,50,95S21.8,51.2,21.8,35.7S31,5,50,5C69,5,78.2,20.1,78.2,35.7z' />
        </svg>
    );
};

PinIcon.propTypes = PinIconProps;

export default PinIcon;

