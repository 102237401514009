import React from "react";
import styled from "styled-components";
import { MobileBreakpoint } from "../config/constants";

const TopBarContainer = styled.div`
  background-color: #6fada8;
  padding: 0;

  @media screen and (max-width: ${MobileBreakpoint}) {
    display: none;
  }
`;

const TopBarInner = styled.div`
  margin: 0 auto;
  color: #ffffff;
  max-width: 1600px;
  padding: 1rem 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: auto 0 auto auto;
    color: inherit;
  }
`;

function TopBar() {
  return (
    <TopBarContainer>
      <TopBarInner></TopBarInner>
    </TopBarContainer>
  );
}

export default TopBar;
