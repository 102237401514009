import React from "react";
import styled from "styled-components";
import { MobileBreakpoint } from "../../config/constants";
import PropTypes from "prop-types";

const FindLockerContainerProps = {
  children: PropTypes.node,
};

const FindLockerContainerWrapper = styled.div`
  display: flex;
  width: 80vw;
  height: 80vh;
  margin: auto;
  background: field;
  z-index: 2;
  position: fixed;
  align-self: center;
  opacity: 1;
  pointer-events: auto;

  @media screen and (max-width: ${MobileBreakpoint}) {
    flex-direction: column-reverse;
    width: 100vw;
    height: 100vh;
    margin-top: -102px;
  }
`;

const FindLockerContainer = props => {
  return <FindLockerContainerWrapper>{props.children}</FindLockerContainerWrapper>;
};

FindLockerContainer.propTypes = FindLockerContainerProps;

export default FindLockerContainer;
