import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const CardContainerStyled = styled.ol`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  width: 100%;
  ${({ style }) => style && css(style)}
`;

const CardContainer = ({ style, children }) => {
  return <CardContainerStyled style={style}>{children}</CardContainerStyled>;
};

CardContainer.propTypes = {
  children: PropTypes.node.isRequired, // Specifies that children must be a React node and is required
  style: PropTypes.object,
};

export default CardContainer;
