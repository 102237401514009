import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, ContentContainer } from '../components/WebLayoutComponents';
import { Api } from '../services/Api';
import styled from 'styled-components';
import Button from '../components/Button/Button';
import Card from '../components/Card/Card';
import CardContainer from '../components/CardContainer/CardContainer';
import { products } from '../products-mock';
import { formatCurrency } from '../utils/cartUtils';
import ProgressBar from '../components/ProgressBar/ProgressBar';

const ContentContainerStyled = styled(ContentContainer)`
    max-width: 460px;
    justify-content: flex-start;
    margin: 0 0;

    &.horizontal {
        gap: 10px;
    }

    h5,
    p {
        margin-top: 0;
    }

    p.primary {
        color: #6fada8;
    }
`;

const Confirmation = () => {
    const navigate = useNavigate();
    const api = new Api();
    api.setup();

    const steps = [
        { label: 'Shipping' },
        { label: 'Review & Payment' },
        { label: 'Order Received' },
    ];

    const location = useLocation();
    const formData = location.state;

    const generateRandomNumber = () => {
        // Generate a random number between 10000000 and 99999999
        const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;
        return randomNumber.toString(); // Convert to string
    };

    const hideDigits = (number) => {
        // Remove spaces from the input string
        const numberWithoutSpaces = number.replace(/\s/g, '');

        // Extract the last four characters
        const lastFourDigits = numberWithoutSpaces.slice(-4);

        // Replace all digits except the last four with asterisks
        const hiddenDigits = numberWithoutSpaces
            .slice(0, -4)
            .replace(/\d/g, '*');

        // Join the hidden digits with the last four digits and add spaces
        const formattedNumber =
            hiddenDigits.replace(/(.{4})/g, '$1 ').trim() +
            ' ' +
            lastFourDigits;

        return formattedNumber;
    };

    return (
        <Container>
            <ContentContainer
                className='full'
                style={{ margin: 0, maxWidth: 800 }}
            >
                <ProgressBar steps={steps} currentStep={3} />
                <h1 style={{ marginBottom: 30 }}>
                    Thank you for your order, {formData.firstName}
                </h1>
                <ContentContainerStyled disableGutters>
                    <p>
                        Your order has been placed and a confirmation email will
                        be sent to {formData?.email}
                    </p>
                </ContentContainerStyled>
                <hr style={{ marginTop: 20, marginBottom: 20 }} />
                <h3>Oder Details</h3>
                <ContentContainerStyled className='horizontal' disableGutters>
                    <h5>Order number:</h5>
                    <p className='primary'>{generateRandomNumber()}</p>
                </ContentContainerStyled>
                <ContentContainerStyled className='horizontal' disableGutters>
                    <h5>Order total:</h5>
                    <p className='primary'>
                        {formatCurrency(formData.orderSubTotal)}
                    </p>
                </ContentContainerStyled>
                <ContentContainerStyled className='horizontal' disableGutters>
                    <h5>Visa:</h5>
                    <p className='primary'>{hideDigits(formData.cardNumber)}</p>
                </ContentContainerStyled>
                <hr style={{ marginTop: 20, marginBottom: 20 }} />
                {Object.keys(formData.lockerData).length > 0 && (
                    <div>
                        <h3>Pick up at:</h3>
                        <ContentContainerStyled
                            className='horizontal'
                            disableGutters
                        >
                            <p>
                                {formData.lockerData.pickupStreet}
                                <br />
                                {formData.lockerData.pickupCity},{' '}
                                {formData.lockerData.pickupState}{' '}
                                {formData.lockerData.pickupPostal}
                            </p>
                        </ContentContainerStyled>
                    </div>
                )}

                <h3>Ship to:</h3>
                <ContentContainerStyled
                    className='horizontal'
                    disableGutters
                    style={{ marginBottom: 20 }}
                >
                    <p>
                        {formData.firstName} {formData.lastName}
                        <br />
                        {formData.streetAddress}
                        <br />
                        {formData.city}, {formData.state} {formData.zip}
                    </p>
                </ContentContainerStyled>
                <ContentContainerStyled
                    className='horizontal'
                    disableGutters
                    style={{ marginTop: 20, marginBottom: 20 }}
                >
                    <Button
                        size='large'
                        className='fullWidth'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Continue Shopping
                    </Button>
                </ContentContainerStyled>
                <hr style={{ marginTop: 20, marginBottom: 20 }} />
                <h3>Sponsored products</h3>
                <ContentContainer
                    className='horizontal full'
                    disableGutters
                    style={{ marginTop: 20, marginBottom: 20 }}
                >
                    <CardContainer>
                        {products.map((product) => (
                            <Card
                                key={product.id}
                                src={product.source}
                                alt={product.alt}
                                title={product.title}
                                price={product.price}
                                link={`/product/${product.id}`}
                                disableActions
                                variant='product'
                            />
                        ))}
                    </CardContainer>
                </ContentContainer>
            </ContentContainer>
        </Container>
    );
};

export default Confirmation;

