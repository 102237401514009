import styled from "styled-components";
import PropTypes from "prop-types";

const BackdropContainer = styled.div`
  &.backdrop-active {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: flex;
    opacity: 1;
    backdrop-filter: blur(10px);
    top: 0;
    transition: opacity 150ms ease-in-out;
  }

  &.backdrop {
    display: flex;
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
  }
`;

const BackdropProps = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export const Backdrop = ({ active, onClick }) => {
  return <BackdropContainer className={active ? "backdrop-active" : "backdrop"} onClick={onClick} />;
};

Backdrop.propTypes = BackdropProps;
