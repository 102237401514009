import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CCContainerProps = {
  children: PropTypes.node,
};

const CCContainerWrapper = styled.div`
  border-radius: 4px;
  box-sizing: content-box;
`;

const CCContainer = props => {
  return <CCContainerWrapper>{props.children}</CCContainerWrapper>;
};

CCContainer.propTypes = CCContainerProps;

export default CCContainer;
