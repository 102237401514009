import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Inner = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  align-items: flex-start;

  p {
    margin: 0;
  }
`;

const UspsLockerPopUpContentProps = {
  children: PropTypes.node,
};

const UspsLockerPopUpContent = props => {
  return <Inner>{props.children}</Inner>;
};

UspsLockerPopUpContent.propTypes = UspsLockerPopUpContentProps;

export default UspsLockerPopUpContent;
