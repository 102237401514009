import PropTypes from 'prop-types';

const InfoIconProps = {
    sx: PropTypes.object,
};

const InfoIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path
                d='M50,100C22.4,100,0,77.6,0,50S22.4,0,50,0s50,22.4,50,50C100,77.6,77.6,100,50,100 M50,9.8C27.8,9.8,9.8,27.8,9.8,50
	c0,22.2,18,40.2,40.2,40.2c22.2,0,40.2-18,40.2-40.2c0,0,0,0,0,0C90.2,27.8,72.2,9.8,50,9.8 M48.7,70.7l8.5-30.2L46.6,41l-7.7,6.1v2
	l2.4-0.4c1.1-0.2,2.1-0.3,3.2-0.4c0.1,0,0.1,0,0.2,0c-0.3,1.6-0.7,3.1-1.1,4.6l-5,17.3c-0.6,1.7-0.9,3.5-1,5.3
	c0,1.5,0.6,2.9,1.7,3.9c1.1,1,2.5,1.5,3.9,1.5h0.1c2.3-0.1,4.5-1,6.2-2.4c2.6-2,4.8-4.5,6.6-7.2l1.1-1.7c0,0-9.6,4.5-8.7,1.9
	C48.5,71.2,48.6,71,48.7,70.7 M53.5,21.3c-3.9,0-7,3.1-7,7c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7c0,0,0,0,0,0
	C60.5,24.4,57.4,21.3,53.5,21.3C53.5,21.3,53.5,21.3,53.5,21.3 M50,100C22.4,100,0,77.6,0,50S22.4,0,50,0s50,22.4,50,50
	C100,77.6,77.6,100,50,100 M50,9.8C27.8,9.8,9.8,27.8,9.8,50s18,40.2,40.2,40.2c22.2,0,40.2-18,40.2-40.2c0,0,0,0,0,0
	C90.2,27.8,72.2,9.8,50,9.8 M48.7,70.7l8.5-30.2L46.6,41l-7.7,6.1v2l2.4-0.4c1.1-0.2,2.1-0.3,3.2-0.4c0.1,0,0.1,0,0.2,0
	c-0.3,1.6-0.7,3.1-1.1,4.6l-5,17.3c-0.6,1.7-0.9,3.5-1,5.3c0,1.5,0.6,2.9,1.7,3.9c1.1,1,2.5,1.5,3.9,1.5h0.1c2.3-0.1,4.5-1,6.2-2.4
	c2.6-2,4.8-4.5,6.6-7.2l1.1-1.7c0,0-9.6,4.5-8.7,1.9C48.5,71.2,48.6,71,48.7,70.7 M53.5,21.3c-3.9,0-7,3.1-7,7c0,3.9,3.1,7,7,7
	c3.9,0,7-3.1,7-7c0,0,0,0,0,0C60.5,24.4,57.4,21.3,53.5,21.3C53.5,21.3,53.5,21.3,53.5,21.3'
            />
        </svg>
    );
};

InfoIcon.propTypes = InfoIconProps;

export default InfoIcon;
