import PropTypes from "prop-types";

const CheckIconProps = {
  sx: PropTypes.object,
  variant: PropTypes.string,
};

const CheckIcon = props => {
  const { sx, variant = "check", ...reducedProps } = props;
  const defaultSize = 24;

  const getPathData = () => {
    if (variant === "check")
      return "M2.6,60.8C0.9,59.3,0,57.1,0,54.6s0.9-4.4,2.6-6.2c1.5-1.5,3.8-2.6,6.2-2.6s4.4,0.9,6.2,2.6l18.3,18.3 l51.9-51.9c1.5-1.8,3.8-2.6,6-2.6c2.4,0,4.4,0.9,6.2,2.6c1.5,1.5,2.6,3.8,2.6,6.2s-0.9,4.4-2.6,6.2L39.5,85.1 c-3.5,3.5-9.3,3.5-12.8,0L2.6,60.8z";

    if (variant === "circle-filled")
      return "M50,0C22.4,0,0,22.4,0,50s22.4,50,50,50s50-22.4,50-50S77.6,0,50,0z M76.6,36.4L42.7,71.6 c-1.1,1.2-2.6,1.8-4.2,1.8c0,0,0,0-0.1,0c-1.6,0-3.1-0.6-4.2-1.7L20.9,58.4c-2.3-2.3-2.3-6.1,0-8.4c2.3-2.3,6.1-2.3,8.4,0 l9,9 L68,28.1c2.3-2.4,6.1-2.4,8.4-0.2C78.8,30.3,78.9,34.1,76.6,36.4z";

    if (variant === "circle-outline")
      return "M80.5,46.6c0.1,1.1,0.2,2.2,0.2,3.3C80.8,69.7,64.8,85.9,45,86C25.2,86.1,9,70.2,8.9,50.4c0,0,0,0,0,0 c-0.1-19.8,15.8-36,35.7-36.1c9.7-0.1,19.1,3.8,25.9,10.8l7-6.6C60,0.6,31.4,0.3,13.6,17.7c0,0,0,0,0,0c-17.8,17.5-18.1,46-0.7,63.9 c17.5,17.8,46,18.1,63.9,0.7c8.7-8.5,13.6-20.2,13.6-32.4c0-3.8-0.5-7.6-1.4-11.3L80.5,46.6z M97.9,14.6c-3-3.1-7.9-3.2-11-0.2 L44.3,54.7l-15-14.8c-3-3-7.9-3-11,0l-0.1,0.1c-3,3-2.9,7.8,0.1,10.8c0,0,0,0,0,0l20.3,20.1c1.5,1.4,3.4,2.3,5.5,2.3 c2,0,3.9-0.8,5.4-2.1l48.1-45.6l0.1-0.1C100.7,22.4,100.8,17.6,97.9,14.6C97.9,14.6,97.9,14.6,97.9,14.6z";

    if (variant === "circle-outline-alt")
      return "M85.1,14.4C65.4-5,33.7-4.7,14.4,14.9C-5,34.6-4.7,66.3,14.9,85.6s51.3,19.1,70.7-0.6c19.2-19.5,19.1-50.8-0.1-70.2 C85.4,14.7,85.2,14.5,85.1,14.4 M20.9,78.6C5.4,62.8,5.5,37.4,21.3,21.9C37,6.3,62.4,6.5,78,22.2C93.5,38,93.4,63.4,77.6,78.9 c-15.6,15.4-40.7,15.4-56.4,0C21.2,78.8,21,78.7,20.9,78.6 M74.2,34.9l-4.3-3.4c-1.2-0.9-2.9-0.8-3.8,0.4c0,0,0,0,0,0l0,0L48.3,54.2 c-1,1.2-2.7,1.3-3.8,0.4c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1l-9.9-9.6c-1.1-1.1-2.8-1.1-3.9,0l0,0l-3.8,3.9 c-1.1,1.1-1.1,2.8,0,3.9 c0,0,0.1,0.1,0.1,0.1l18.7,18.5c1.1,1,2.8,1,3.9-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2l25.2-32.2C75.6,37.5,75.4,35.8,74.2,34.9";
  };

  return (
    <svg
      {...reducedProps}
      viewBox="0 0 100 100"
      style={{ width: defaultSize, ...sx }} // Apply the transform style directly
    >
      <path d={getPathData()} />
    </svg>
  );
};

CheckIcon.propTypes = CheckIconProps;

export default CheckIcon;
