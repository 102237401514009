import PropTypes from 'prop-types';

const FavoriteIconProps = {
    sx: PropTypes.object,
};

const FavoriteIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 32 32'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M27.288 2.777c-4.105-1.964-9.004-0.177-10.936 3.99-0.135 0.29-0.251 0.653-0.354 1.075-0.103-0.422-0.219-0.785-0.352-1.075-1.934-4.167-6.828-5.954-10.934-3.99-4.1 1.964-5.859 6.935-3.927 11.103 1.933 4.167 15.209 16.115 15.209 16.115s0.001-0.014 0.003-0.036c0.003 0.022 0.004 0.036 0.004 0.036s13.282-11.948 15.215-16.115c1.931-4.168 0.177-9.139-3.928-11.103z' />
        </svg>
    );
};

FavoriteIcon.propTypes = FavoriteIconProps;

export default FavoriteIcon;
