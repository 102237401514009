import PropTypes from "prop-types";

import styled from "styled-components";
import DeleteIcon from "../static/img/Icon-Delete.svg";
import { MobileBreakpoint } from "../config/constants";

const ModalContentContainerStyled = styled.div`
  display: flex;
  max-width: 814px;
  min-width: 310px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  background: field;
  z-index: 2;
  position: fixed;
  opacity: 1;
  pointer-events: auto;
  flex-direction: column;
  padding: 40px;

  .content-header {
    position: relative;
    display: flex;
    width: 100%;

    h3 {
      margin-top: 0;
    }
  }

  .content-container {
    position: relative;
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;

    p {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  .close-button-container {
    position: absolute;
    right: 0;
  }

  #learn-more-exit:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    padding: 25px;
  }
`;

const ModalContentContainer = props => {
  const { title, titleWidth = "50%", children, exit, actions } = props;

  const handleKeyDown = event => {
    // Trigger the same action as onClick when Enter key is pressed
    if (event.key === "Enter") {
      exit();
    }
  };

  return (
    <ModalContentContainerStyled>
      <div className="content-header">
        <h3
          style={{
            width: typeof titleWidth === "string" ? titleWidth : `${titleWidth}px`,
          }}
        >
          {title}
        </h3>
        <span className="close-button-container">
          <img
            id="learn-more-exit"
            src={DeleteIcon}
            alt="Exit"
            onClick={exit}
            onKeyDown={handleKeyDown} // Handle keyboard events
            tabIndex="0" // Make the element focusable
          />
        </span>
      </div>
      <div className="content-container">{children}</div>
      <div className="actions-container">{actions}</div>
    </ModalContentContainerStyled>
  );
};

ModalContentContainer.propTypes = {
  title: PropTypes.string.isRequired, // Specifies that title must be a string and is required
  titleWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Specifies that titleWidth must be a number or a string
  children: PropTypes.node.isRequired, // Specifies that children must be a React node and is required
  exit: PropTypes.func.isRequired, // Specifies that exit must be a function and is required
  actions: PropTypes.node, // Specifies that actions must be a React node
};

export default ModalContentContainer;
