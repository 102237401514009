import React from "react";
import styled from "styled-components";
import { MobileBreakpoint } from "../../config/constants";
import PropTypes from "prop-types";

const ShippingAddressWrapper = styled.div`
  max-width: 1046px;
  width: 100%;

  h1 {
    font-weight: normal;
  }

  .selected-locker-container {
    display: flex;
    gap: 20;
    margin-top: 20;
  }

  .selected-locker-address-container {
    display: flex;
    width: 50%;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .selected-locker-container {
      flex-direction: column;
      margin-left: 20px;
      margin-right: auto;
    }

    .selected-locker-address-container {
      width: 100%;
    }

    .checkout-ship-to-address {
      margin-left: 43px;
    }
  }
`;

const ShippingAddressContainerProps = {
  children: PropTypes.node,
};

const ShippingAddressContainer = props => {
  return <ShippingAddressWrapper {...props}>{props.children}</ShippingAddressWrapper>;
};

ShippingAddressContainer.propTypes = ShippingAddressContainerProps;

export default ShippingAddressContainer;
