import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
`;

export const Loading = props => {
  return (
    <LoadingContainer {...props}>
      <CircularProgress sx={{ margin: "0 auto", color: "#6fada8" }} />
    </LoadingContainer>
  );
};
