import { Routes, Route, Navigate } from "react-router-dom";
import Cart from "../features/Cart";
import Checkout from "../features/Checkout";
import Confirmation from "../features/Confirmation";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import { Outlet } from "react-router-dom/dist";
import Footer from "../components/Footer";
import Home from "../features/Home";
import Accessories from "../features/Accessories";
import Product from "../features/Product";
import ReturnReason from "../features/Returns/ReturnReason";
import Orders from "../features/Orders";
import OrderStatus from "../features/OrderStatus";
import HeaderNavigation from "../components/HeaderNavigation";
import ReturnShippingMethod from "../features/Returns/ReturnShippingMethod";
import ReturnInstructions from "../features/Returns/ReturnInstructions";
import OrderStatusDashboard from "../features/OrderStatusDashboard";

function App() {
  return (
    <Routes>
      <Route
        element={
          <>
            <TopBar />
            <Header />
            <HeaderNavigation />
            <Outlet />
            <Footer />
          </>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/accessories/:category?/:secondaryCategory?" element={<Accessories />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
      </Route>

      <Route
        element={
          <>
            <TopBar />
            <Header returns />
            <Outlet />
            <Footer style={{ marginTop: 0 }} />
          </>
        }
      >
        <Route path="/return">
          <Route path="reason" element={<ReturnReason />} />
          <Route path="method" element={<ReturnShippingMethod />} />
          <Route path="confirmation" element={<ReturnInstructions />} />
        </Route>
        <Route path="/orders" element={<Orders />} />
        <Route path="/order/pickup" element={<OrderStatusDashboard />} />
      </Route>

      <Route
        element={
          <>
            <Header checkout />
            <Outlet />
            <Footer />
          </>
        }
      >
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/order-status" element={<OrderStatus />} />
      </Route>

      <Route
        element={
          <>
            <TopBar />
            <Header />
            <HeaderNavigation />
            <Outlet />
            <Footer />
          </>
        }
      >
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}

export default App;
