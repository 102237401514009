import PropTypes from 'prop-types';

const InstagramIconProps = {
    sx: PropTypes.object,
};

const InstagramIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M50,13.1c12,0,13.4,0,18.2,0.3c4.4,0.2,6.8,0.9,8.4,1.5c2.1,0.8,3.6,1.8,5.2,3.4c1.6,1.6,2.5,3.1,3.4,5.2c0.6,1.6,1.3,4,1.5,8.4c0.2,4.7,0.3,6.2,0.3,18.2s0,13.4-0.3,18.2c-0.2,4.4-0.9,6.8-1.5,8.4c-0.8,2.1-1.8,3.6-3.4,5.2c-1.6,1.6-3.1,2.5-5.2,3.4c-1.6,0.6-4,1.3-8.4,1.5c-4.7,0.2-6.2,0.3-18.2,0.3c-12,0-13.4,0-18.2-0.3c-4.4-0.2-6.8-0.9-8.4-1.5c-2.1-0.8-3.6-1.8-5.2-3.4c-1.6-1.6-2.5-3.1-3.4-5.2c-0.6-1.6-1.3-4-1.5-8.4c-0.2-4.7-0.3-6.2-0.3-18.2s0-13.4,0.3-18.2c0.2-4.4,0.9-6.8,1.5-8.4c0.8-2.1,1.8-3.6,3.4-5.2c1.6-1.6,3.1-2.5,5.2-3.4c1.6-0.6,4-1.3,8.4-1.5C36.6,13.2,38,13.1,50,13.1 M50,5C37.8,5,36.2,5.1,31.4,5.3c-4.8,0.2-8.1,1-10.9,2.1c-3,1.1-5.5,2.7-8,5.2c-2.5,2.5-4,5-5.2,8c-1.1,2.9-1.9,6.1-2.1,10.9C5.1,36.2,5,37.8,5,50c0,12.2,0.1,13.8,0.3,18.6c0.2,4.8,1,8.1,2.1,10.9c1.1,3,2.7,5.5,5.2,8c2.5,2.5,5,4,8,5.2c2.9,1.1,6.1,1.9,10.9,2.1C36.2,94.9,37.8,95,50,95c12.2,0,13.8-0.1,18.6-0.3c4.8-0.2,8.1-1,10.9-2.1c3-1.1,5.5-2.7,8-5.2c2.5-2.5,4-5,5.2-8c1.1-2.9,1.9-6.1,2.1-10.9C94.9,63.8,95,62.2,95,50s-0.1-13.8-0.3-18.6c-0.2-4.8-1-8.1-2.1-10.9c-1.1-3-2.7-5.5-5.2-8c-2.5-2.5-5-4-8-5.2c-2.9-1.1-6.1-1.9-10.9-2.1C63.8,5.1,62.2,5,50,5L50,5z M50,26.9c-12.8,0-23.1,10.3-23.1,23.1S37.2,73.1,50,73.1c12.8,0,23.1-10.3,23.1-23.1S62.8,26.9,50,26.9z M50,65c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15S58.3,65,50,65z M74,20.6c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4S77,20.6,74,20.6z' />
        </svg>
    );
};

InstagramIcon.propTypes = InstagramIconProps;

export default InstagramIcon;

