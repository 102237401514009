import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CartItemInnerProps = {
  children: PropTypes.node,
};

const CartItemInnerWrapper = styled.div`
  margin: auto 0 auto 10px;

  p {
    margin: 0;
  }

  p:first-of-type {
    margin-bottom: 5px;
  }
`;

const CartItemInner = props => {
  return <CartItemInnerWrapper>{props.children}</CartItemInnerWrapper>;
};

CartItemInner.propTypes = CartItemInnerProps;

export default CartItemInner;
