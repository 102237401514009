import { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Define a styled component with a class
const ProgressItem = styled.div`
  align-items: center;
  color: #575757;
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 235px;

  &::before {
    background-color: #d8d8d8;
    content: "";
    position: absolute;
    top: 22px;
    height: 10px;
    width: 100%;
    z-index: -1;
  }

  &:first-of-type {
    &::before {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }

  &:last-of-type {
    &::before {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &.active {
    font-weight: 600;
    &::before {
      background-color: #ff5500;
    }
  }
`;

const ProgressBarProps = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
};

const ProgressBar = props => {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState({});

  useEffect(() => {
    if (props.steps && props.steps.length > 0 && props.currentStep)
      setCurrentStep(props.currentStep <= props.steps.length ? props.currentStep : props.steps.length);

    if (props.steps && props.steps.length > 0) setSteps(props.steps);
  }, [props.currentStep, props.steps]);

  if (steps && steps.length > 0) {
    return (
      <div style={{ justifyContent: "justify-content", display: "flex" }}>
        {steps.map((step, index) => {
          const isActive = index + 1 <= currentStep;
          let activeProps = {
            borderRadius: "50%",
            boxShadow: "0 0 0 8px #D8D8D8 inset",
          };

          if (isActive)
            activeProps = {
              ...activeProps,
              boxShadow: "0 0 0 8px #FF5500 inset",
            };

          return (
            <ProgressItem key={index + 1} className={isActive ? "active" : ""}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                href="http://www.w3.org/1999/xlink"
                width={52}
                height={52}
                viewBox="0 0 52 52"
                fill="none"
                style={{ ...activeProps }}
              >
                <path
                  fill="#FFFFFF"
                  style={{
                    filter: "drop-shadow(rgba(0, 0, 0, 0.16) 0px 1px 3px)",
                  }}
                  d="M26,8c9.9,0,18,8.1,18,18s-8.1,18-18,18S8,35.9,8,26S16.1,8,26,8z"
                />
                <path fill="#595959" d="M35.6,15.3l3.4,3.1L23.2,35.7L12.8,25.4l3.2-3.3l6.8,6.9L35.6,15.3z" />
              </svg>
              {step.label}
            </ProgressItem>
          );
        })}
      </div>
    );
  }
};

ProgressBar.propTypes = ProgressBarProps;

export default ProgressBar;
