import { isEmpty } from "ramda";
import { create } from "apisauce";

export class Api {
  setup() {
    this.appsauce = create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: ["application/json", "image/png", "application/vnd.apple.pkpass, text/csv"],
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      timeout: 8000,
    });
  }

  async postGetCSVLocationsByZip(postalCode, radiusInput) {
    const response = await this.appsauce.post("/callCSVLockerLookup", {
      city: "",
      state: "",
      "zip-code": postalCode,
      radius: radiusInput,
      "max-locations": null,
      service: "PARCELLOCKERS",
    });

    if (!isEmpty(response)) {
      if (response.ok) {
        return { response };
      } else if (response.status === 302 || response.status === 414) {
        return { response: "error" };
      }
    } else {
      return [];
    }
  }

  async postGetCSVLocationsByCityState(cityName, stateName, radiusInput) {
    const response = await this.appsauce.post("/callCSVLockerLookup", {
      city: cityName,
      state: stateName,
      "zip-code": "",
      radius: radiusInput,
      "max-locations": null,
      service: "PARCELLOCKERS",
    });

    if (!isEmpty(response)) {
      if (response.ok) {
        return { response };
      } else if (response.status === 302 || response.status === 414) {
        return { response: "error" };
      }
    } else {
      return [];
    }
  }
}

export default Api;
