import React from "react";
import styled from "styled-components";
import chevron from "../../static/img/chevron-down-arrow.png";
import { MobileBreakpoint } from "../../config/constants";
import PropTypes from "prop-types";

const InputContainerProps = {
  width: PropTypes.string,
  children: PropTypes.node,
};

const InputContainerWrapper = styled.div`
  width: ${props => props.width};

  p {
    margin-bottom: 10px;
  }

  input {
    padding: 13px 10px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 2px;
  }

  select {
    padding: 13px 10px;
    width: 100%;
    background-image: url(${chevron}); /* Use a sprite image containing both normal and flipped arrow */
    background-repeat: no-repeat;
    background-color: #ffffff;
    background-size: 18px auto; /* Set the size of the arrow */
    background-position: calc(100% - 10px) center; /* Initial position of the arrow */
    border: 1px solid #707070;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: background-position 0.3s ease; /* Add a transition for smooth animation */
  }

  select.open {
    background-position: calc(100% - 10px) center; /* Adjust background position for the flipped arrow */
    transform: rotate(180deg); /* Rotate the select element */
  }

  div {
    color: red;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    width: 100%;
  }
`;

const InputContainer = props => {
  return <InputContainerWrapper width={props.width}>{props.children}</InputContainerWrapper>;
};

InputContainer.propTypes = InputContainerProps;

export default InputContainer;
