import PropTypes from 'prop-types';

const PencilIconProps = {
    sx: PropTypes.object,
};

const PencilIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path
                d='M95.7,29.6L86,39.3L60.1,13.4l9.7-9.7c2.3-2.3,6.1-2.3,8.4,0l17.5,17.5C98,23.5,98,27.3,95.7,29.6z M55.3,18.3l25.9,25.9
	l-43.1,43c-7,2.2-14,4.3-21,6.5c-16.5,5.1-16.4,8.5-11.9-7.4l7.1-25L55.3,18.3z M33.6,82.8l-17-17L12,82.2c-2.9,10.4-3,8.2,7.8,4.9
	C24.4,85.6,29,84.2,33.6,82.8z'
            />
        </svg>
    );
};

PencilIcon.propTypes = PencilIconProps;

export default PencilIcon;

