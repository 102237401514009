// components/CartPopper/CartPopper.js
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../Button/Button";
import { useCart } from "../../providers/CartContext";
import { formatCurrency } from "../../utils/cartUtils";
import { MobileBreakpoint } from "../../config/constants";

const CartPopperProps = {
  handleClose: PropTypes.func,
};

const CartPopperStyled = styled.div`
  font-size: 1.1875em;
  color: #000000;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${MobileBreakpoint}) {
  }
`;

const CartPopperHeader = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
`;

const CartPopperHeaderTop = styled.div`
  color: inherit;
  flex-direction: row;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const CartPopperHeaderActions = styled.div`
  color: inherit;
`;

const CartPopperHeaderItemsCount = styled.div`
  color: inherit;
  display: flex;
`;

const CartPopperHeaderSubtotal = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;

  h5 {
    font-size: inherit;
    margin: 0;
  }

  .subTotalAmount {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-align: right;
  }
`;

const CartPopperContent = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
`;

const CartPopperActions = styled.div`
  color: inherit;
  display: block;
  text-align: center;
`;

const CheckoutButton = styled(Button)`
  width: 100%;
`;

const StyledHr = styled.hr`
  border: 0;
  height: 1px;
  border-top: 1px solid #707070;
  margin: 2rem -2rem;
`;

const CartItem = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const CartItemInner = styled.div`
  margin: auto 0 auto 10px;

  p {
    margin: 0;
  }

  p:first-of-type {
    margin-bottom: 5px;
  }
`;

const CartPopper = ({ handleClose }) => {
  const navigate = useNavigate();
  const { cart } = useCart();

  const subtotal = cart.items.reduce((total, item) => {
    // Extract numerical value from the price string and add it to the total
    const itemPrice = parseFloat(item.price.replace("$", "") * item.quantity);

    return total + itemPrice;
  }, 0);

  return (
    <CartPopperStyled>
      <CartPopperHeader>
        <CartPopperHeaderTop>
          <CartPopperHeaderItemsCount>
            {`${cart.items.length} item${cart.items.length !== 1 ? "s" : ""} in Cart.`}
          </CartPopperHeaderItemsCount>
          <CartPopperHeaderSubtotal>
            <h5>Cart Subtotal</h5>
            <span className="subTotalAmount">{`${formatCurrency(subtotal)}`}</span>
          </CartPopperHeaderSubtotal>
        </CartPopperHeaderTop>
        <CartPopperHeaderActions>
          <CheckoutButton
            onClick={() => {
              navigate("/checkout");
              if (handleClose) handleClose();
            }}
          >
            Proceed to Checkout
          </CheckoutButton>
        </CartPopperHeaderActions>
      </CartPopperHeader>
      <StyledHr />
      <CartPopperContent>
        {cart?.items?.length > 0 &&
          Object.entries(cart.items).map(([itemId, itemData]) => (
            <CartItem key={`${itemId}-${itemData.id}`}>
              <img
                src={itemData.source}
                alt={itemData.alt}
                style={{
                  height: 80 + "px",
                }}
              />
              <CartItemInner>
                <p>{itemData.title}</p>
                <p className="lighter-text">Qty: {itemData.quantity}</p>
                <p>{formatCurrency(parseFloat(itemData.price.replace("$", "")) * itemData.quantity)}</p>
              </CartItemInner>
            </CartItem>
          ))}
      </CartPopperContent>
      <StyledHr />
      <CartPopperActions>
        <Button
          variant="link"
          onClick={() => {
            navigate("/cart");
            if (handleClose) handleClose();
          }}
          style={{ fontSize: "inherit" }}
        >
          View and Edit Cart
        </Button>
      </CartPopperActions>
    </CartPopperStyled>
  );
};

CartPopper.propTypes = CartPopperProps;

export default CartPopper;
