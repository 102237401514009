import React from "react";
import styled from "styled-components";
import { MobileBreakpoint } from "../../config/constants";
import PropTypes from "prop-types";

const InputRowProps = {
  children: PropTypes.node,
};

const InputRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    flex-direction: column;
    gap: 0;
  }
`;

const InputRow = props => {
  return <InputRowWrapper {...props}>{props.children}</InputRowWrapper>;
};

InputRow.propTypes = InputRowProps;

export default InputRow;
