import PropTypes from "prop-types";
import styled from "styled-components";
import FavoriteIcon from "../icons/Favorite";
import StatsIcon from "../icons/Stats";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";

const CardProps = {
  src: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  disableActions: PropTypes.bool,
  variant: PropTypes.oneOf(["product", "standard"]),
  content: PropTypes.element,
};

const CardStyled = styled.li`
  width: ${({ variant }) => (variant === "product" ? "calc(100% / 5)" : "100%")};
  padding: 1rem;
  min-width: 182px;

  ${({ variant }) =>
    variant === "product" &&
    `
    &:hover {
      background: #fff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.29);

      & > div {
        opacity: 1;
      }
    }
  `}

  ${({ variant }) =>
    variant === "standard" &&
    `
      background: #fff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.29);
  `}
`;

const CardContent = styled.div`
  color: #000000;
  display: block;

  img {
    width: 100%;
  }

  .title {
    margin: 1rem 0;
    display: block;
  }

  p {
    color: inherit;
  }
`;

const CardTitle = styled.div`
  display: flex;
  background-color: #6eada8;
  color: #fff;
  margin: -1rem -1rem 1rem -1rem;
  padding: 1rem;
  font-family: "Helvetica Neue Bold";
  justify-content: center;
`;

const CardActions = styled.div`
  display: flex;
  gap: 20px;
  opacity: 0;
`;

const Card = ({ src, alt, link, title, price, disableActions = false, variant = "standard", content }) => {
  const navigate = useNavigate();

  if (variant === "standard") {
    return (
      <CardStyled variant={variant}>
        {title && <CardTitle>{title}</CardTitle>}
        <CardContent>{content}</CardContent>
      </CardStyled>
    );
  }
  if (variant === "product") {
    if (!src || !alt || !title || !link || !price) {
      return "You are missing needed props to render a product card";
    }

    return (
      <CardStyled variant={variant}>
        <CardContent>
          <Link to={link}>
            <img src={src} alt={alt} />
          </Link>
          <Link className="title" to={link}>
            {title}
          </Link>
          <p>
            <strong>{price}</strong>
          </p>
          <Button style={{ minWidth: "100%" }} onClick={() => navigate(link)}>
            Add to Cart
          </Button>
        </CardContent>
        {!disableActions && (
          <CardActions>
            <Button iconButton>
              <FavoriteIcon />
            </Button>
            <Button iconButton>
              <StatsIcon />
            </Button>
          </CardActions>
        )}
      </CardStyled>
    );
  }
};

Card.propTypes = CardProps;

export default Card;
