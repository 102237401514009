// cartUtils
export const currencies = [
    { code: 'USD', name: 'United States Dollar', symbol: '$' },
    { code: 'EUR', name: 'Euro', symbol: '€' },
    { code: 'GBP', name: 'British Pound Sterling', symbol: '£' },
    { code: 'JPY', name: 'Japanese Yen', symbol: '¥' },
    { code: 'AUD', name: 'Australian Dollar', symbol: '$' },
    { code: 'CAD', name: 'Canadian Dollar', symbol: '$' },
    { code: 'CHF', name: 'Swiss Franc', symbol: 'Fr' },
    { code: 'CNY', name: 'Chinese Yuan Renminbi', symbol: '¥' },
    { code: 'SEK', name: 'Swedish Krona', symbol: 'kr' },
    { code: 'NZD', name: 'New Zealand Dollar', symbol: '$' },
    { code: 'KRW', name: 'South Korean Won', symbol: '₩' },
    { code: 'SGD', name: 'Singapore Dollar', symbol: '$' },
    { code: 'NOK', name: 'Norwegian Krone', symbol: 'kr' },
    { code: 'MXN', name: 'Mexican Peso', symbol: '$' },
    { code: 'INR', name: 'Indian Rupee', symbol: '₹' },
    { code: 'BRL', name: 'Brazilian Real', symbol: 'R$' },
    { code: 'RUB', name: 'Russian Ruble', symbol: '₽' },
    { code: 'ZAR', name: 'South African Rand', symbol: 'R' },
    { code: 'HKD', name: 'Hong Kong Dollar', symbol: '$' },
    { code: 'TRY', name: 'Turkish Lira', symbol: '₺' },
    { code: 'AED', name: 'United Arab Emirates Dirham', symbol: 'د.إ' },
    { code: 'SAR', name: 'Saudi Riyal', symbol: '﷼' },
    { code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp' },
    { code: 'THB', name: 'Thai Baht', symbol: '฿' },
    { code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM' },
];

/**
 * formatCurrency
 *
 * Example usage:
 *
 * const formatted1 = formatCurrency("$1200.84"); outputs $1,200.84
 * const formatted2 = formatCurrency("1200"); outputs $1,200.00
 * formatted3 = formatCurrency("1,200.00"); outputs $1,200.00
 */

export const formatCurrency = (value = '0', currencyCode = 'USD', onInput = false) => {
    const currency = currencies.find(
        (cur) => cur.code === currencyCode.toUpperCase(),
    );

    if (!currency) {
        throw new Error(`Currency with code ${currencyCode} not found.`);
    }

    const { symbol } = currency;

    // Ensure value is converted to a string before attempting to use replace method
    const parsedValue =
        value === null ? 0 : parseFloat(String(value).replace(/[^0-9.]/g, ''));

    const formattedValue = parsedValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    if (onInput) {
        return formattedValue;
    }

    return `${symbol}${formattedValue}`;
};
