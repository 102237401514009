import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const RightContainerProps = {
  children: PropTypes.node,
};

const RightContainerWrapper = styled.div`
  max-width: 524px;
  width: 100%;
`;

const RightContainer = props => {
  return <RightContainerWrapper>{props.children}</RightContainerWrapper>;
};

RightContainer.propTypes = RightContainerProps;

export default RightContainer;
