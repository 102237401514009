import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const OrderSummaryBoxProps = {
  children: PropTypes.node,
};

const OrderSummaryBoxWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  margin-top: 85px;
  font-weight: normal;
`;

const OrderSummaryBox = props => {
  return <OrderSummaryBoxWrapper {...props}>{props.children}</OrderSummaryBoxWrapper>;
};

OrderSummaryBox.propTypes = OrderSummaryBoxProps;

export default OrderSummaryBox;
