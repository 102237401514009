import { forwardRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { MobileBreakpoint } from "../../config/constants";

const ButtonProps = {
  children: PropTypes.node.isRequired,
  iconButton: PropTypes.bool,
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
  variant: PropTypes.oneOf(["contained", "outlined", "text", "link"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf(["primary", "secondary"]), // New color prop
};

const commonStyles = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-width: 170px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: unset;
  min-height: unset;
  gap: 10px;

  &:disabled {
    background-color: #f7f7f7;
    color: grey;
    cursor: default;
  }

  &.fullWidth {
    width: 100%;
  }
`;

const BaseButtonStyled = styled.button`
  ${commonStyles}

  &.fullWidth {
    width: 100%;
  }

  ${props => {
    switch (props.size) {
      case "small":
        return css`
          font-size: 0.875rem;
          padding: 8px;
        `;
      case "large":
        return css`
          font-size: 1.2rem;
          padding: 16px;
        `;
      default:
        return css`
          font-size: 1rem;
          padding: 13px;
        `;
    }
  }}

  @media screen and (max-width: ${MobileBreakpoint}) {
    &.mobile-full-width {
        width: 100%;
        max-width: 1200px;
    }
  }
`;

const ContainedButtonStyled = styled(BaseButtonStyled)`
  background-color: ${({ color }) =>
    color === "primary" ? "#6fada8" : "white"}; // Apply different colors based on the 'color' prop
  color: ${({ color }) => (color === "primary" ? "#fff" : "#000")}; // Text color based on the 'color' prop
  box-shadow: ${({ color }) => (color === "secondary" ? "0px 3px 6px #D6D6D6" : "unset")};

  &:hover {
    color: #000;
    background-color: #ededed; // Hover background color
  }

  &:disabled {
    background-color: #f7f7f7;
    color: grey;
    cursor: not-allowed;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    &.mobile-full-width {
      width: 100%;
      max-width: 1200px;
    }
  }
`;

const OutlinedButtonStyled = styled(BaseButtonStyled)`
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;

  &:hover {
    color: #000;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${props => {
    switch (props.size) {
      case "small":
        return css`
          padding: 7px;
        `;
      case "large":
        return css`
          padding: 15px;
        `;
      default:
        return css`
          padding: 12px;
        `;
    }
  }}
`;

const TextButtonStyled = styled(BaseButtonStyled)`
  color: inherit;
  padding: 0;
  font-weight: inherit;

  &:hover {
    background-color: inherit;
    color: inherit;
  }

  &:disabled {
    opacity: 0.5
    cursor: not-allowed;
  }
`;

const LinkButtonStyled = styled(BaseButtonStyled)`
  color: #3573b1;
  background-color: transparent;
  text-decoration: none;
  border: none;
  padding: 0;
  font-weight: 400;
  margin: 0;
  min-width: unset;

  &:hover {
    color: #3573b1;
    text-decoration: underline;
    background-color: transparent;
  }
`;

const IconButtonStyled = styled(BaseButtonStyled)`
  background-color: transparent;
  color: #707070;
  fill: #707070;
  width: auto;
  min-width: auto;
  padding: 0;

  &:hover {
    color: #000000;
    fill: #000000;
    background-color: transparent;
    width: auto;
    min-width: auto;

    svg {
      fill: inherit;
    }
  }

  &:disabled {
    background-color: transparent;
    color: grey;
  }

  svg {
    fill: inherit;
  }

  .active {
    color: #000000;
    fill: #000000;
  }
`;

const ButtonVariantMap = {
  contained: ContainedButtonStyled,
  outlined: OutlinedButtonStyled,
  text: TextButtonStyled,
  link: LinkButtonStyled,
};

const Button = forwardRef(
  (
    {
      children,
      iconButton = false,
      endIcon,
      startIcon,
      variant = "contained",
      size = "medium",
      color = "primary", // Default color is primary
      ...props
    },
    ref
  ) => {
    const ButtonComponent = iconButton && iconButton === true ? IconButtonStyled : ButtonVariantMap[variant];

    return (
      <ButtonComponent
        ref={ref}
        size={size}
        color={color} // Pass the color prop down to the styled component
        aria-label={props.children}
        {...props}
      >
        {startIcon}
        {children}
        {endIcon}
      </ButtonComponent>
    );
  }
);

Button.propTypes = ButtonProps;

export default Button;
