// Product Page
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Alert from "../components/Alert/Alert";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Button from "../components/Button/Button";
import FavoriteIcon from "../components/icons/Favorite";
import StatsIcon from "../components/icons/Stats";
import { Container, ContentContainer } from "../components/WebLayoutComponents";
import { MobileBreakpoint } from "../config/constants";
import { products } from "../products-mock";
import { useCart } from "../providers/CartContext";
import { fadeIn, fadeOut } from "../utils/transitions";

const ImageContainer = styled(ContentContainer)`
  margin-top: 6rem;

  @media screen and (max-width: ${MobileBreakpoint}) {
    margin-top: 0;

    .product-image {
      max-width: 100%;
    }
  }
`;

const AddCartButtonContainer = styled(ContentContainer)`
  .cart-button {
    width: auto;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .cart-button {
      width: 100%;
    }
  }
`;

const SideProductInfoContainer = styled(ContentContainer)`
  margin-bottom: 0;

  .instock-line {
    margin-bottom: 10px;
  }

  .product-price {
    color: "#575757";
  }

  .inner-product-info {
    text-align: right;
    margin: auto 0 auto auto;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .instock-line {
      margin: auto 0 0;
    }

    .product-price {
      margin-bottom: 0;
    }

    .inner-product-info {
      margin: auto 0 7px auto;
    }
  }
`;

const ButtonStyled = styled(Button)`
  background-color: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0;
  min-width: unset;
  font-weight: inherit;

  &:hover {
    background-color: inherit;
    color: inherit;
  }
`;

const ReviewTitle = styled.h5`
  position: absolute;
  top: -2.25rem;
  margin: 0;
  padding: 0.5rem 1rem;
  left: -1px;
  background: #fff;
  border: 1px solid #333333;
  border-bottom: none;
`;

const AlertWrapper = styled(ContentContainer).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !["show"].includes(prop) && defaultValidatorFn(prop),
})`
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} 0.3s ease-in-out;

  @media screen and (max-width: ${MobileBreakpoint}) {
    position: fixed;
    top: 25px;
  }
`;

const Product = () => {
  const navigate = useNavigate();

  const { cart, addToCart } = useCart();

  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false); // Initialize showAlert as false
  const [product, setProduct] = useState();
  const [itemQuantity, setItemQuantity] = useState(1); // default to 1 item for the quantity
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewCountText, setReviewCountText] = useState("Be the first to review this product");

  /**
   * This is likely not needed later if the site is
   * launched with ability to add favorites an comparisons
   */
  const disableActions = true;

  const handleAddToCart = () => {
    const product = products.find(product => product.id === parseInt(id));

    // Check if item quantity is greater than 0 before adding to cart
    if (itemQuantity > 0) {
      addToCart(product, itemQuantity);
      setShowAlert(true);
    } else {
      // Display an error message or prevent the action
      // For example, you can show an alert
      alert("Quantity cannot be empty");
    }
  };

  useEffect(() => {
    if (!product) {
      setProduct(products.find(product => product.id === parseInt(id)));
      setLoading(false);
    }

    if (product) {
      setReviewCount(product.reviews.length);

      if (reviewCount > 0) setReviewCountText(`${reviewCount} ${reviewCount === 1 ? "Review" : "Reviews"}`);
    }
  }, [product, id, reviewCount, cart]);

  const handleAlertCloseClick = () => {
    setShowAlert(false);
  };

  return (
    <Container>
      {!loading && product && (
        <>
          <Breadcrumbs
            breadcrumbs={[
              {
                uuid: 0,
                title: "Home",
                url: "/",
              },
              {
                uuid: product.id,
                title: product.title,
              },
            ]}
          />

          {showAlert && (
            <AlertWrapper className="full" show={showAlert ? "true" : "false"}>
              <Alert handleCloseClick={handleAlertCloseClick}>
                <div>
                  You added {product.title} to your{" "}
                  <Button variant="link" onClick={() => navigate("/cart")}>
                    shopping cart
                  </Button>
                  .
                </div>
              </Alert>
            </AlertWrapper>
          )}

          <ContentContainer className="horizontal full">
            <h2 style={{ fontSize: "30px", marginBottom: "10px" }} className="mobile-only">
              {product.title}
            </h2>
            <ImageContainer className="horizontal" disableGutters>
              <img src={product.source} alt={product.alt} className="product-image" />
            </ImageContainer>

            <ContentContainer className="horizontal full" disableGutters>
              <ContentContainer className="full" disableGutters>
                <ContentContainer className="full desktop-only" disableGutters>
                  <h1>{product.title}</h1>
                  <Button variant="link">{reviewCountText}</Button>
                </ContentContainer>

                <ContentContainer className="horizontal full" disableGutters style={{ gap: 0 }}>
                  <SideProductInfoContainer className="full" disableGutters style={{ display: "flex" }}>
                    <h2 className="product-price">{product.price}</h2>
                    <div className="inner-product-info">
                      <p className="instock-line">
                        <strong>{product.stock > 0 ? "IN STOCK" : "SOLD OUT"}</strong>
                      </p>
                      <p style={{ marginTop: "10px" }} className="desktop-only lighter-text">
                        SKU#: {product.sku}
                      </p>
                    </div>
                  </SideProductInfoContainer>
                  <p style={{ marginTop: "10px" }} className="mobile-only">
                    SKU#: {product.sku}
                  </p>
                </ContentContainer>

                <hr />

                <ContentContainer className="full" style={{ marginBottom: "2rem" }} disableGutters>
                  <p className="lighter-text">Qty</p>
                  <input
                    type="number"
                    value={itemQuantity || ""}
                    id="productQuantity"
                    min={1}
                    onChange={event => {
                      // Ensure that the value is non-negative
                      let newValue = Math.max(0, parseInt(event.target.value));

                      // If newValue is 0, and user didn't select 0 via keyboard (typing or arrow keys), set it to 1
                      if (newValue === 0) {
                        newValue = 1;
                      }

                      // Update the state with the new value
                      setItemQuantity(newValue);
                    }}
                  />
                </ContentContainer>

                <AddCartButtonContainer className="full" disableGutters style={{ marginBottom: "2rem" }}>
                  <Button onClick={handleAddToCart} className="cart-button">
                    Add to Cart
                  </Button>
                </AddCartButtonContainer>

                {!disableActions && (
                  <ContentContainer className="full horizontal" disableGutters>
                    <ButtonStyled startIcon={<FavoriteIcon />} onClick={() => console.log("add to wish list clicked")}>
                      ADD TO WISH LIST
                    </ButtonStyled>

                    <ButtonStyled startIcon={<StatsIcon />} onClick={() => console.log("add to compare clicked")}>
                      ADD TO COMPARE
                    </ButtonStyled>
                  </ContentContainer>
                )}
              </ContentContainer>
            </ContentContainer>
          </ContentContainer>

          <ContentContainer
            className="horizontal full"
            style={{
              marginTop: "4rem",
              marginBottom: "4rem",
            }}
          >
            <ContentContainer
              className="full"
              style={{
                border: "1px solid #333333",
                padding: "2rem",
                position: "relative",
                background: "#fff",
              }}
            >
              <ReviewTitle>Reviews</ReviewTitle>
              <h4>You're reviewing</h4>
              <h3>{product.title}</h3>
            </ContentContainer>
          </ContentContainer>
        </>
      )}
    </Container>
  );
};

export default Product;
