import styled from "styled-components";
import greenMapPin from "../static/img/green-map-pin.png";
import redMapPin from "../static/img/red-map-pin.png";
import grayMapPin from "../static/img/gray-map-pin.png";
import uspsMapPin from "../static/img/usps-map-pin.png";
import DeleteIcon from "../static/img/Icon-Delete.svg";
import IconSearch from "../static/img/Icon-Search.PNG";
import Button from "./Button/Button";
import Api from "../services/Api";
import { useState, useEffect } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { useForm } from "react-hook-form";
import { AddressChecker } from "../common/utils";
import { Loading } from "./Loading";
import { MobileBreakpoint } from "../config/constants";

const MapListContainer = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
  height: 100%;

  @media screen and (max-width: ${MobileBreakpoint}) {
    width: 100%;
    max-height: 600px;
  }

  /* Hide Scrollbar for Chrome, Safari and Opera */

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const MapListBlock = styled.div`
  display: flex;
  flex-direction: column;
  background: field;
  padding: 10px;
  border-left: 0 solid #333366;
  border-bottom: 2px solid #d3d3d3;
  transition: border-left 150ms ease-in-out;

  button {
    max-width: 150px;
  }
`;

const MapListBlockUpper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  img {
    height: 35px;
  }

  p {
    margin: 0 0 0 10px;
  }

  span {
    margin-left: auto;
  }

  .map-pin {
    height: 35px;
    width: 35px;
    min-width: 32.08px;
    margin: 0;
    text-align: center;
    color: white;
    background-size: cover;
    font-size: 0.85rem;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .operational {
    background-image: url(${greenMapPin});
  }

  b.operational {
    color: #218748;
    background-image: none;
    margin-left: 10px;
  }

  .maintenance {
    background-image: url(${grayMapPin});
  }

  b.maintenance {
    color: #595959;
    background-image: none;
    margin-left: 10px;
  }

  .not-configured {
    background-image: url(${uspsMapPin});
  }

  b.not-configured {
    color: #336;
    background-image: none;
    margin-left: 10px;
  }

  .not-operational {
    background-image: url(${redMapPin});
  }

  b.not-operational {
    color: #e71921;
    background-image: none;
    margin-left: 10px;
  }

  .upper-inner {
    margin-right: 0;
    background: transparent;
  }
`;

const MapListBlockUpperInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  width: 100%;
`;

const MapListBlockNameStatus = styled.div`
  p {
    margin-right: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  span {
    position: relative;
    right: 12px;
    font-size: 16px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    p {
      margin-right: 5px;
    }

    span {
      right: 0;
    }
  }
`;

const MapListEmpty = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #f7f7f7;
  justify-content: center;

  h2 {
    color: #595959;
    margin: auto;
  }
`;

const SearchContainer = styled.div`
  padding: 20px;
  display: block;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #ffffff;
  border-bottom: 1px solid #707070;

  h2 {
    width: fit-content;
  }

  img {
    float: right;
    height: 26px;
    width: 26px;
  }

  #ship-to-locker-exit:hover {
    cursor: pointer;
  }
`;

const SearchContainerInner = styled.div`
  display: flex;

  input {
    margin-left: 10px;
    width: 100%;
    border: none;
    outline: none;
  }
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SearchBar = styled.div`
  border: 2px solid black;
  border-radius: 8px;
  display: flex;
  padding: 5px;
  height: 38px;
  width: 100%;

  img {
    height: 22px;
    width: 20.3px;
    cursor: pointer;
    margin-right: 10px;
  }
`;

export const MapListHolder = props => {
  const {
    lockerList,
    radiusInput,
    handleLockerSelect,
    locationInput,
    exit,
    raiseLocationInputFromMap,
    centerChangeRaiseFunction,
    setLockerListRaiseFunction,
  } = props;

  const api = new Api();
  api.setup();

  const [locationErrorState, setLocationErrorState] = useState(false);
  const [noLockers, setNoLockers] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const formSchema = z.object({
    location: z
      .string()
      .regex(/^\d{5}$|([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/, {
        message:
          'Invalid Format: Enter a complete Zip or "City, State" combo to find possible locker locations within.',
      })
      .trim(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: zodResolver(formSchema),
  });

  const formValues = watch();

  useEffect(() => {
    if (props.raiseLocationInputFromMap) raiseLocationInputFromMap(formValues.location);
  }, [formValues.location]);

  const handleLocationData = data => {
    if (data !== undefined) {
      if (data.response.data.sourceLatitude === 0 && data.response.data.lockers === null) {
        // Location does not exist
        setLockerListRaiseFunction(data.response);
        setLoadingData(false);
      } else if (data.response.data.lockers === null) {
        // Valid location but no lockers exist in the area
        setLockerListRaiseFunction(data.response);
        setNoLockers(true);
        setLoadingData(false);
      } else {
        // Search comes back with results
        setLockerListRaiseFunction(data.response);
        centerChangeRaiseFunction();
        setLoadingData(false);
      }
    } else {
      // Couldn't make API call
      setLoadingData(false);
    }
  };

  const getLocationsByZip = async (postalCode, radius) => {
    const result = await api.postGetCSVLocationsByZip(postalCode, radius);
    handleLocationData(result);
  };

  const getLocationsByCityState = async (cityName, stateName, radius) => {
    const result = await api.postGetCSVLocationsByCityState(cityName, stateName, radius);
    handleLocationData(result);
  };

  const handleLockerSearch = () => {
    setNoLockers(false);
    if (!locationErrorState) setLocationErrorState(true);
    setLoadingData(true);
    const postalCode = AddressChecker(formValues.location).zip;
    const cityName = AddressChecker(formValues.location).city;
    const stateName = AddressChecker(formValues.location).state;

    if (postalCode) {
      getLocationsByZip(postalCode, radiusInput);
    } else getLocationsByCityState(cityName, stateName, radiusInput);
  };

  const createBlock = () => {
    return (
      <MapListContainer>
        <SearchContainer>
          <form id="map-search" onSubmit={handleSubmit(handleLockerSearch)}>
            <SearchHeader>
              <h2>Find Smart Locker</h2>
              <img id="ship-to-locker-exit" src={DeleteIcon} alt="Exit" onClick={exit} onKeyDown={exit} />
            </SearchHeader>
            <SearchContainerInner>
              <SearchBar>
                <input
                  type="text"
                  placeholder="City and State, or ZIP Code™"
                  defaultValue={locationInput}
                  {...register("location")}
                />
                <Button onClick={handleSubmit(handleLockerSearch)} iconButton>
                  <img src={IconSearch} alt="search-icon" />
                </Button>
              </SearchBar>
            </SearchContainerInner>
            <div style={{ color: "red", marginTop: "5px" }}>{errors.location?.message}</div>
          </form>
        </SearchContainer>

        {loadingData && <Loading />}
        {!loadingData &&
          lockerList?.data?.lockers?.map((location, index) => {
            let pinClassName = "map-pin";

            if (location.lockerStatus === "Operational") {
              pinClassName = "map-pin operational";
            } else if (location.lockerStatus === "Not-Configured") {
              pinClassName = "map-pin not-configured";
            } else if (location.lockerStatus === "Not-Operational") {
              pinClassName = "map-pin not-operational";
            } else if (location.lockerStatus === "Maintenance") {
              pinClassName = "map-pin maintenance";
            }

            return (
              <MapListBlock key={location.uniqueId}>
                <MapListBlockNameStatus>
                  <span
                    style={{
                      right: 0 + "px",
                      fontWeight: 600,
                    }}
                  >{`${Number(location.distance).toFixed(2)} mi`}</span>
                  <p>{location.locationName}</p>
                </MapListBlockNameStatus>
                <MapListBlockUpper>
                  <p className={pinClassName}>{index + 1}</p>
                  <MapListBlockUpperInner className="upper-inner">
                    <p>
                      <strong>USPS Smart Locker</strong>
                    </p>
                    <p>{`${location.locationAddress1} ${location.locationCity}, ${location.locationState} ${location.locationZIP5}`}</p>
                  </MapListBlockUpperInner>
                </MapListBlockUpper>
                <Button onClick={() => handleLockerSelect(location)}>Ship Here</Button>
              </MapListBlock>
            );
          })}
        {!lockerList?.data?.lockers && noLockers && (
          <MapListEmpty>
            <h2>No Lockers Found</h2>
          </MapListEmpty>
        )}
      </MapListContainer>
    );
  };

  return createBlock();
};

export default MapListHolder;
