import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../Button/Button';
import { useCart } from '../../providers/CartContext';
import { formatCurrency } from '../../utils/cartUtils';

const Container = styled.div`
    background-color: #f0f0f0;
    width: 340px;
`;

const ContainerInner = styled.div`
    padding: 2rem;
`;

const CartSummary = () => {
    const navigate = useNavigate();
    const { cart } = useCart();

    const subtotal = cart.items.reduce((total, item) => {
        // Extract numerical value from the price string and add it to the total
        const itemPrice =
            parseFloat(item.price.replace('$', '')) * item.quantity;

        return total + itemPrice;
    }, 0);

    return (
        <Container>
            <ContainerInner>
                <h2>Summary</h2>
                <hr />
                <h5>Cart Subtotal</h5>
                <span className='subTotalAmount'>{`${formatCurrency(
                    subtotal,
                )}`}</span>
                <hr />
                <Button
                    className='fullWidth'
                    onClick={() => navigate('/checkout')}
                    style={{ marginTop: '1rem' }}
                >
                    Proceed to Checkout
                </Button>
                <div
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '1rem auto 0px',
                        flexDirection: 'column',
                        gap: '0.5rem',
                    }}
                >
                    <div>-- or --</div>
                    <Button variant='link' onClick={() => navigate('/')}>
                        Continue Shopping
                    </Button>
                </div>
            </ContainerInner>
        </Container>
    );
};

export default CartSummary;

