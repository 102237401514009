// Cart Page
import React from "react";
import { Link } from "react-router-dom";
import { Container, ContentContainer } from "../components/WebLayoutComponents";
import styled from "styled-components";
import { useCart } from "../providers/CartContext";
import CartSummary from "../components/CartSummary/CartSummary";
import { formatCurrency } from "../utils/cartUtils";
import Button from "../components/Button/Button";
import TrashIcon from "../components/icons/Trash";

const TableContainer = styled.div`
  width: 100%;
`;

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  border-bottom: 1px solid #000;
  font-weight: 700;
`;

const TableRow = styled.tr``;

const TableHeadCell = styled.th`
  color: #000000;
  border-bottom: 1px solid #999999;
  padding: 1rem;
  text-align: left;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
    text-align: right;
  }
`;

const TableCell = styled.td`
  padding: 1rem;
  vertical-align: top;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
    text-align: right;
  }
`;

const Cart = () => {
  const { cart, updateProductQuantity, removeFromCart } = useCart();

  return (
    <Container>
      <ContentContainer className="full">
        <h1>Shopping Cart</h1>
        <hr />
        <br />
        <ContentContainer className="horizontal full" disableGutters>
          <ContentContainer className="full" disableGutters>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Item</TableHeadCell>
                    <TableHeadCell>Price</TableHeadCell>
                    <TableHeadCell>Qty</TableHeadCell>
                    <TableHeadCell>Subtotal</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(!cart || cart?.items?.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <div
                          style={{
                            padding: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <h3>There is nothing in your cart.</h3>
                          <p>
                            Go <Link to={"/"}>find</Link> something amazing to add.
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {cart?.items?.length > 0 &&
                    Object.entries(cart.items).map(([itemId, itemData]) => (
                      <>
                        <TableRow key={`${itemId}-${itemData.id}`}>
                          <TableCell className="lighter-text">
                            {/* Display item information here */}
                            <ContentContainer className="full horizontal" disableGutters>
                              <img
                                src={itemData.source}
                                alt={itemData.alt}
                                style={{
                                  height: 150 + "px",
                                }}
                              />
                              {itemData.title}
                            </ContentContainer>
                          </TableCell>
                          <TableCell className="lighter-text">{formatCurrency(itemData.price)}</TableCell>
                          <TableCell>
                            <input
                              type="number"
                              value={itemData.quantity || ""}
                              min={1}
                              id="productQuantity"
                              onChange={event => {
                                // Ensure that the value is non-negative
                                let newValue = Math.max(1, parseInt(event.target.value || 1));

                                // If newValue is NaN (due to empty string), set it to 1
                                if (isNaN(newValue)) {
                                  newValue = 1;
                                }

                                // Update the state with the new value
                                updateProductQuantity(itemData.id, newValue);
                              }}
                            />
                          </TableCell>
                          <TableCell className="lighter-text">
                            {formatCurrency(parseFloat(itemData.price.replace("$", "")) * itemData.quantity)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #707070",
                            }}
                            colSpan={4}
                            align="right"
                          >
                            <Button
                              iconButton
                              style={{
                                width: 16,
                              }}
                              onClick={() => removeFromCart(itemData.id)}
                            >
                              <TrashIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ContentContainer>
          <ContentContainer>
            <CartSummary />
          </ContentContainer>
        </ContentContainer>
      </ContentContainer>
    </Container>
  );
};

export default Cart;
