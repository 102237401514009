// date.js

export function getCurrentDateInfo() {
    // Create a new Date object
    const currentDate = new Date();

    // Array of month names
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // Array of day names
    const dayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    // Get the current month (returns a number from 0 to 11)
    const currentMonth = monthNames[currentDate.getMonth()];

    // Get the current day of the week (returns a number from 0 to 6)
    const currentDayOfWeek = dayNames[currentDate.getDay()];

    // Get the current day of the month
    const currentDayOfMonth = currentDate.getDate();

    return {
        currentMonth,
        currentDayOfWeek,
        currentDayOfMonth,
    };
}

export function getFutureMonthAndDay() {
    // Generate a random number between 0 and 30
    const daysToAdd = Math.floor(Math.random() * 31);

    const currentDate = new Date();
    const futureDate = new Date(
        currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000,
    );

    // Array of month names
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // Get the future month
    const futureMonth = monthNames[futureDate.getMonth()];

    // Get the future day of the month
    const futureDayOfMonth = futureDate.getDate();

    return `${futureMonth} ${futureDayOfMonth}`;
}

