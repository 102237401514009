import PropTypes from 'prop-types';

const TwitterIconProps = {
    sx: PropTypes.object,
};

const TwitterIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M33.3,86.6c-10.4,0-20.1-3.1-28.3-8.3c1.4,0.2,2.9,0.3,4.4,0.3c8.7,0,16.6-3,22.9-7.9c-8.1-0.2-14.9-5.5-17.2-12.8c1.1,0.2,2.3,0.3,3.5,0.3c1.7,0,3.3-0.2,4.9-0.6C15,55.8,8.6,48.3,8.6,39.4c0-0.1,0-0.2,0-0.2c2.5,1.4,5.3,2.2,8.4,2.3c-5-3.3-8.2-9-8.2-15.4c0-3.4,0.9-6.6,2.5-9.3C20.4,28,34,35.3,49.3,36.1c-0.3-1.4-0.5-2.8-0.5-4.2c0-10.2,8.3-18.5,18.5-18.5c5.3,0,10.1,2.2,13.5,5.8c4.2-0.8,8.2-2.4,11.7-4.5c-1.4,4.3-4.3,7.9-8.1,10.2c3.7-0.4,7.3-1.4,10.6-2.9c-2.5,3.7-5.6,7-9.2,9.6c0,0.8,0.1,1.6,0.1,2.4C85.8,58.4,67.3,86.6,33.3,86.6' />
        </svg>
    );
};

TwitterIcon.propTypes = TwitterIconProps;

export default TwitterIcon;

