import styled from "styled-components";
import InfoIcon from "../../static/img/info-icon.svg";

const AlertContainer = styled.div`
  padding: 15px 30px;
  border: 1px solid #333366;
  border-left: 12px solid #333366;
  border-radius: 10px;
  display: flex;
`;

const USPSAlert = () => {
  return (
    <AlertContainer>
      <img src={InfoIcon} alt="Information" />
      <div style={{ margin: "auto 10px", fontWeight: "bold" }}>All items must be returned by August 14, 2024</div>
    </AlertContainer>
  );
};

export default USPSAlert;
