import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ChevronIconProps = {
    variant: PropTypes.oneOf([
        'down-alt',
        'down-circle-outline',
        'down',
        'left-alt',
        'left-circle-outline',
        'left',
        'right-alt',
        'right-circle-outline',
        'right',
        'up-alt',
        'up-circle-outline',
        'up',
        undefined,
    ]),
    sx: PropTypes.object,
};

const ChevronIcon = (props) => {
    const { variant = 'right', sx, ...reducedProps } = props;
    const [variantDirection, setVariantDirection] = useState('rotate(0)');
    const defaultSize = 24;

    // Default Variation
    const [variation, setVariation] = useState(
        'M80.6,50L28.8,98.5c-2.2,2-5.5,2-7.7,0c-2-1.8-2.1-4.9-0.3-6.9c0.1-0.1,0.2-0.2,0.3-0.3L65.1,50L21,8.7c-2-1.8-2.1-4.9-0.3-6.9c0.1-0.1,0.2-0.2,0.3-0.3c2.2-2,5.5-2,7.7,0L80.6,50',
    );

    useEffect(() => {
        if (
            variant === 'down' ||
            variant === 'down-circle-outline' ||
            variant === 'down-alt'
        )
            setVariantDirection('rotate(90deg)');

        if (
            variant === 'left' ||
            variant === 'left-circle-outline' ||
            variant === 'left-alt'
        )
            setVariantDirection('rotate(180deg)');

        if (
            variant === 'up' ||
            variant === 'up-circle-outline' ||
            variant === 'up-alt'
        )
            setVariantDirection('rotate(270deg)');

        // Set Alt Icon
        if (
            variant === 'right-alt' ||
            variant === 'down-alt' ||
            variant === 'left-alt' ||
            variant === 'up-alt'
        )
            setVariation(
                'M83.3,50L34.6,97C30.5,101,24,101,19.8,97c-3.9-3.7-4.1-9.9-0.4-13.8c0.1-0.1,0.3-0.3,0.4-0.4L53.7,50l-34-32.8c-3.9-3.7-4.1-10-0.3-13.9c0.1-0.1,0.2-0.2,0.3-0.3C23.9-1,30.4-1,34.5,2.9L83.3,50z',
            );

        // Set Circle Outline Icon
        if (
            variant === 'right-circle-outline' ||
            variant === 'down-circle-outline' ||
            variant === 'left-circle-outline' ||
            variant === 'up-circle-outline'
        )
            setVariation(
                'M0,50C0,22.4,22.4,0,50,0s50,22.4,50,50s-22.4,50-50,50S0,77.6,0,50z M92.3,50c0-23.3-19-42.3-42.3-42.3S7.7,26.7,7.7,50s19,42.3,42.3,42.3S92.3,73.3,92.3,50z M75.6,50L45.6,79c-2.5,2.4-6.5,2.4-9.1,0c-2.4-2.3-2.5-6.1-0.3-8.5c0.1-0.1,0.2-0.2,0.3-0.3L57.4,50L36.5,29.8c-2.4-2.3-2.5-6.1-0.2-8.5c0.1-0.1,0.1-0.1,0.2-0.2c2.5-2.4,6.5-2.4,9.1,0L75.6,50z',
            );
    }, [variant]);

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{
                transform: variantDirection,
                width: defaultSize,
                fill: 'inherit',
                display: 'flex',
                ...sx,
            }} // Apply the transform style directly
        >
            <path d={variation} />
        </svg>
    );
};

ChevronIcon.propTypes = ChevronIconProps;

export default ChevronIcon;

