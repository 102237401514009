import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from '../components/WebLayoutComponents';

const Accessories = () => {
    const { category } = useParams();

    useEffect(() => {
        const defaultBreadcrumbs = [
            {
                uuid: 0,
                title: 'Home',
                url: '/',
            },
        ];

        defaultBreadcrumbs.push({
            uuid: 0,
            title: category,
            url: `/${category}`,
        });
    }, [category]);

    return <Container></Container>;
};

export default Accessories;
