import React from "react";
import styled from "styled-components";
import Button from "../../components/Button/Button";
import USPSAlert from "../../components/USPSAlert/USPSAlert";
import { Container, ContentContainer } from "../../components/WebLayoutComponents";
import { MobileBreakpoint } from "../../config/constants";
import qrCode from "../../static/img/uspsinnovationqrcode.svg";

const HeaderContainer = styled(ContentContainer)`
  gap: 30px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    padding-top: 20px;
  }
`;

const BorderedContent = styled(ContentContainer)`
  background-color: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 10;
  overflow: hidden;

  @media screen and (max-width: ${MobileBreakpoint}) {
    border: none;
  }
`;

const OrdersContent = styled.div`
  max-width: 1237px;
  margin: 0 auto;
  width: 100%;
`;

const OrdersContainer = styled.div`
  display: flex;
  padding: 20px 0;
  margin: 0 60px;

  .big-bold-header {
    font-weight: bold;
    font-size: 30px;
  }

  .gray-info-text {
    font-size: 24px;
    color: #565959;
  }

  &.medium-padding {
    padding: 30px 0;
  }

  &.space-between {
    justify-content: space-between;
  }

  li {
    list-style-position: outside;
    margin-left: 20px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    margin: 0;
  }
`;

const QRHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const GreyBackgroundDiv = styled.div`
  background-color: #f7f7f7;
`;

const ReturnInstructions = () => {
  return (
    <Container className="gray-white-background">
      <OrdersContent>
        <HeaderContainer className="horizontal full mobile-column">
          <h1 style={{ margin: 0 }}>Your Return Instructions</h1>
          <Button style={{ maxHeight: "44px", margin: "auto 0" }}>Print Label and instructions</Button>
          <Button style={{ maxHeight: "44px", margin: "auto 0" }} className="secondary-button">
            Email copy of label
          </Button>
        </HeaderContainer>
        <ContentContainer className="full" style={{ marginTop: "30px" }}>
          <ContentContainer
            disableGutters
            className="full"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #CDCDCD",
              borderRadius: 10,
              overflow: "hidden",
              marginBottom: "20px",
            }}
          >
            <USPSAlert>
              <div>All items must be returned by August 14, 2024</div>
            </USPSAlert>
          </ContentContainer>
          <BorderedContent disableGutters className="full">
            <div style={{ backgroundColor: "#FFFFFF" }}>
              <OrdersContainer>
                <div>
                  <span className="big-bold-header smaller-mb">I don't have a printer.</span>
                  <ul
                    className="medium-mb"
                    style={{ listStyleType: "disc", listStylePosition: "inside", marginTop: "20px" }}
                  >
                    <li className="smaller-mb">
                      Please check{" "}
                      <a
                        href="https://www.uspssmartpackagelockers.com/locker-location"
                        target="_blank"
                        rel="noreferrer"
                        className="inline-text-link"
                      >
                        USPS Smart Locker Locator
                      </a>{" "}
                      to ensure that your local Post Office has a USPS Smart Locker before proceeding to that location.
                    </li>
                    <li className="smaller-mb">You will not receive your refund until the item is returned.</li>
                    <li className="smaller-mb">We'll email you a return shipping code.</li>
                    <li>
                      Show the return shipping code on your smartphone at the USPS Smart Locker. Just bring your item in
                      its original manufacture's packaging. If you assembled the item, please disassemble before
                      returing.
                    </li>
                  </ul>
                  <span className="big-bold-header">QR Code Label</span>
                  <p>Scan the QR code.</p>
                  <QRHolder>
                    <GreyBackgroundDiv style={{ width: "152px", height: "152px", margin: "auto" }}>
                      <img
                        src={qrCode}
                        alt="QR code"
                        style={{ maxHeight: "124px", maxWidth: "124px", margin: "14px" }}
                      />
                    </GreyBackgroundDiv>
                  </QRHolder>
                  <p style={{ textAlign: "center" }}>Download Return Code</p>
                  <span className="big-bold-header smaller-mb">I've got a printer.</span>
                  <ul
                    className="medium-mb"
                    style={{ listStyleType: "disc", listStylePosition: "inside", marginTop: "20px" }}
                  >
                    <li className="smaller-mb">You will not receive your refund until the item is returned.</li>
                    <li>Print out the return label.</li>
                  </ul>
                </div>
              </OrdersContainer>
            </div>
          </BorderedContent>
        </ContentContainer>
      </OrdersContent>
    </Container>
  );
};

export default ReturnInstructions;
