import PropTypes from 'prop-types';

const TrashIconProps = {
    sx: PropTypes.object,
};

const TrashIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path
                d='M12.9,41.8h7.7V95h58.9V41.8h7.6V31.6H12.9V41.8z M61.1,46c0.2-1.9,2-3.3,3.9-3
	c1.6,0.2,2.8,1.4,3,3v36.8c-0.2,1.9-2,3.3-3.9,3c-1.6-0.2-2.8-1.4-3-3V46z M46.6,46c0.2-1.9,2-3.3,3.9-3c1.6,0.2,2.8,1.4,3,3v36.8
	c-0.2,1.9-2,3.3-3.9,3c-1.6-0.2-2.8-1.4-3-3V46z M32,46c0.1-1.8,1.7-3.2,3.4-3.1c1.8-0.1,3.3,1.3,3.4,3.1v36.8
	c-0.1,1.8-1.7,3.2-3.4,3.1c-1.8,0.1-3.3-1.3-3.4-3.1V46z M82.4,17H69.6V15c0-5.5-4.5-10-10-10h-19C35,5,30.5,9.4,30.5,15
	c0,0,0,0,0,0V17H17.7c-2.6,0-4.7,2.1-4.8,4.7c0,0,0,0,0,0v6h74.2v-5.9C87.1,19.1,85,17,82.4,17 M62.7,14.9V17H37.3V15
	c0-1.8,1.4-3.2,3.2-3.3h18.9C61.2,11.7,62.7,13.1,62.7,14.9L62.7,14.9z'
            />
        </svg>
    );
};

TrashIcon.propTypes = TrashIconProps;

export default TrashIcon;

