import PropTypes from 'prop-types';

const StatsIconProps = {
    sx: PropTypes.object,
};

const StatsIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 32 32'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M7.974 28.014v-7.977h-7.974v7.977h7.974zM19.953 3.985h-7.933v24.028h7.933v-24.028zM31.974 12.033h-7.961v15.98h7.961v-15.98z' />
        </svg>
    );
};

StatsIcon.propTypes = StatsIconProps;

export default StatsIcon;

