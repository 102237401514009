import PropTypes from 'prop-types';

const CartIconProps = {
    sx: PropTypes.object,
};

const CartIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M100,17.9c0,2.4-2,4-4,4h-8.8L73.7,61.3c-0.7,2.4-3.4,4-5.7,4l0,0H17.8l0,0c-2.7,0-5.1-1.7-5.7-4l-11.8-31C0,29.6,0,29,0,28 c0-3.4,2.7-6.1,6.4-6.1l0,0h52.9c2.4,0,4,2,4,4s-1.7,4.4-4,4.4H9.4l9.8,26.9h46.5l13.5-39.4c1-2.4,3.4-4,6.1-4l0,0h10.4 C98,13.5,100,15.5,100,17.9z M24.6,67.4c-5.1,0-9.4,4-9.4,9.4s4,9.4,9.4,9.4s9.4-4,9.4-9.4S29.6,67.4,24.6,67.4z M59.3,67.4 c-5.1,0-9.4,4-9.4,9.4s4,9.4,9.4,9.4s9.4-4,9.4-9.4S64.3,67.4,59.3,67.4z' />
        </svg>
    );
};

CartIcon.propTypes = CartIconProps;

export default CartIcon;

