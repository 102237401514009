import PropTypes from 'prop-types';

const LockersIconProps = {
    sx: PropTypes.object,
};

const LockersIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <circle style={{ fill: '#FFFFFF' }} cx='50' cy='50' r='42.9' />
            <path d='M50,0C22.4,0,0,22.4,0,50s22.4,50,50,50s50-22.4,50-50S77.6,0,50,0z M50,92.9C26.3,92.9,7.1,73.7,7.1,50S26.3,7.1,50,7.1S92.9,26.3,92.9,50S73.7,92.9,50,92.9z M82.1,72.9H17.9V70h64.3V72.9z M82.1,28.8H17.9v2.9h64.3V28.8z M33.9,33.9H21.1V41h12.9V33.9z M33.9,43.2H21.1v7.1h12.9V43.2z M33.9,52.4H21.1v7.1h12.9V52.4z M33.9,61.7H21.1v7.1h12.9V61.7z M48.9,33.9H36.1V41h12.9V33.9zM48.9,43.2H36.1v7.1h12.9V43.2z M48.9,52.4H36.1v7.1h12.9V52.4z M48.9,61.7H36.1v7.1h12.9V61.7z M63.9,33.9H51.1V41h12.9V33.9zM63.9,61.7H51.1v7.1h12.9V61.7z M78.9,33.9H66.1V41h12.9V33.9z M78.9,43.2H66.1v7.1h12.9V43.2z M78.9,52.4H66.1v7.1h12.9V52.4zM78.9,61.7H66.1v7.1h12.9V61.7z M63.9,43.2v16.4H51.1V43.2H63.9z M59.6,55.3h-4.3v2.1h4.3V55.3z M62.5,44.6h-10v7.9h10V44.6z' />
        </svg>
    );
};

LockersIcon.propTypes = LockersIconProps;

export default LockersIcon;

