import PropTypes from 'prop-types';

const FacebookIconProps = {
    sx: PropTypes.object,
};

const FacebookIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M85,95H15C9.5,95,5,90.5,5,85V15C5,9.5,9.5,5,15,5h70c5.5,0,10,4.5,10,10v70C95,90.5,90.5,95,85,95z' />
            <path
                style={{ fill: '#FFFFFF' }}
                d='M66.5,63v32l-14.1,0V63H41V50h11.4v-9.9c0-11.3,6.7-17.5,17-17.5c4.9,0,10.1,0.9,10.1,0.9v11.1h-5.7
	c-5.6,0-7.3,3.5-7.3,7V50H79l-2,13H66.5z'
            />
        </svg>
    );
};

FacebookIcon.propTypes = FacebookIconProps;

export default FacebookIcon;

