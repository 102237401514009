import Card from "../components/Card/Card";
import CardContainer from "../components/CardContainer/CardContainer";
import { Container, ContentContainer } from "../components/WebLayoutComponents";
import { products } from "../products-mock";

const Home = () => {
  return (
    <Container>
      <ContentContainer className="full">
        <h1>Home Page</h1>

        <p>
          Welcome to Authentik Adventures! We're the ultimate outdoor brand for Gen Z. Explore, connect, and embrace
          adventure with our high-quality gear and sustainable ethos. Let's embark on unforgettable journeys together!
          #AuthentikAdventures #GenZOutdoors
        </p>
      </ContentContainer>

      <ContentContainer className="horizontal full">
        <h3 style={{ marginBottom: "0" }}>Featured</h3>
      </ContentContainer>

      <ContentContainer className="horizontal full" disableGutters>
        <CardContainer>
          {products.map(product => (
            <Card
              key={product.id}
              src={product.source}
              alt={product.alt}
              title={product.title}
              price={product.price}
              link={`/product/${product.id}`}
              disableActions
              variant="product"
            />
          ))}
        </CardContainer>
      </ContentContainer>
    </Container>
  );
};

export default Home;
