import { Container, ContentContainer } from "../../components/WebLayoutComponents";
import styled from "styled-components";
import Button from "../../components/Button/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MobileBreakpoint } from "../../config/constants";
import DropdownChevron from "../../static/img/chevron-down-arrow.png";

const HeaderContainer = styled(ContentContainer)`
  .header {
    font-size: 54px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .header {
      font-size: 46px;
    }
  }
`;

const OrdersContent = styled.div`
  max-width: 1237px;
  margin: 0 auto;
  width: 100%;
`;

const OrdersContainer = styled.div`
  padding: 20px 0;

  .order-delivery-date {
    font-weight: bold;
    font-size: 30px;
  }
`;

const OrderDropdownContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  width: fit-content;
  height: 65px;
  padding: 20px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    width: 100%;

    span {
      text-align: center;
      width: 100%;
    }
  }
`;

const OrderItemContainer = styled.div``;

const OrderProductContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .product-image {
    max-width: 205px;
    max-height: 206px;
    min-width: 78px;
    min-height: 78px;
    border-radius: 16px;
  }

  .product-title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 1rem;
  }

  .product-subtitle {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .product-image {
      max-width: 78px;
    }

    .product-title {
      font-size: 26px;
      margin-left: 20px;
    }

    .product-subtitle {
      font-size: 18px;
      margin-top: 10px;
    }
  }
`;

const OrderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  margin-top: 10px;
  margin-right: 30px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    margin-left: 0;
  }
`;

const ReturnItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 361px;
  min-width: 31%;
  width: 100%;

  .return-header {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .return-reason-dropdown {
    border: 0.5px solid #cdcdcd;
    border-radius: 3px;
    padding: 12px 10px;
    margin-bottom: 30px;
    max-width: 311px;
    width: 100%;
  }

  .return-comments-text-area {
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    width: 100%;

    .return-comments-text-area {
      width: 100%;
    }
  }
`;

const ReturnReason = () => {
  const navigate = useNavigate();

  const [returnItem, setReturnItem] = useState();
  const [charactersRemaining, setCharactersRemaining] = useState(200);
  const [textAreaValue, setTextAreaValue] = useState("");

  function handleTextAreaChange(e) {
    setTextAreaValue(e.target.value);
    const textAreaLength = document.getElementById("return-comments").value.length;
    setCharactersRemaining(200 - textAreaLength);
  }

  function onContinuePress() {
    navigate("/return/method");
  }

  useEffect(() => {
    if (!sessionStorage.getItem("return-item")) navigate("/orders");
    else setReturnItem(JSON.parse(sessionStorage.getItem("return-item")));
  }, []);

  return (
    <Container className="gray-white-background">
      <OrdersContent>
        <HeaderContainer className="horizontal full">
          <h1 className="header">Item to return</h1>
        </HeaderContainer>
        <ContentContainer className="horizontal full" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              gap: 10,
              backgroundColor: "#F3F3F3",
              borderRadius: 10,
              padding: 5,
            }}
          >
            <Button variant="contained" color="secondary" className="mobile-full-width">
              Orders
            </Button>
            <Button variant="text" className="desktop-only">
              Not Yet Shipped
            </Button>
            <Button variant="text" className="desktop-only">
              Cancelled Orders
            </Button>
          </div>
          <OrderDropdownContainer>
            <span style={{ fontWeight: "600" }}>Past 3 Months</span>
            <img
              src={DropdownChevron}
              alt="dropdown arrow"
              style={{ width: "18px", height: "11px", margin: "auto 0 auto 10px" }}
            />
          </OrderDropdownContainer>
        </ContentContainer>
        <ContentContainer className="full" style={{ marginTop: "30px" }}>
          <ContentContainer
            disableGutters
            className="full"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #CDCDCD",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <div style={{ backgroundColor: "#FFFFFF" }}>
              <OrdersContainer>
                <OrderItemContainer key={returnItem?.productInfo.id}>
                  <ContentContainer>
                    <span className="order-delivery-date">Delivered {returnItem?.deliveredDate}</span>
                    <OrderProductContainer>
                      <img
                        src={returnItem?.productInfo.source}
                        alt={returnItem?.productInfo.alt}
                        className="product-image desktop-only"
                      />
                      <OrderDetailContainer>
                        <div style={{ display: "flex" }}>
                          <img
                            src={returnItem?.productInfo.source}
                            alt={returnItem?.productInfo.alt}
                            className="product-image mobile-only"
                          />
                          <span className="product-title">{returnItem?.productInfo.title}</span>
                        </div>
                        <span className="product-subtitle">
                          Return or replace items: Eligible through {returnItem?.returnByDate}
                        </span>
                        <ReturnItemContainer className="mobile-only">
                          <span className="return-header">Why are you returning this?</span>
                          <select name="return-reason" id="return-reason" className="return-reason-dropdown">
                            <option value="Wrong item was sent">Wrong item was sent</option>
                          </select>
                          <span className="return-header">Comments (optional):</span>
                          <textarea
                            name="return-comments"
                            id="return-comments"
                            cols="30"
                            rows="5"
                            maxLength={200}
                            onChange={handleTextAreaChange}
                            value={textAreaValue}
                            className="return-comments-text-area"
                          />
                          <p style={{ fontSize: "14px", color: "#565959", marginBottom: "0" }}>
                            {charactersRemaining} characters remaining.
                          </p>
                          <p style={{ fontSize: "14px", color: "#565959", marginTop: "20px", marginBottom: "30px" }}>
                            NOTE: We aren't able to offer policy exceptions in response to comments. Do not include
                            personal information as these comments may be shared with external service providers to
                            identify product defects.
                          </p>
                          <Button onClick={onContinuePress} className="mobile-full-width">
                            Continue
                          </Button>
                        </ReturnItemContainer>
                      </OrderDetailContainer>
                      <ReturnItemContainer className="desktop-only">
                        <span className="return-header">Why are you returning this?</span>
                        <select name="return-reason" id="return-reason" className="return-reason-dropdown">
                          <option value="Wrong item was sent">Wrong item was sent</option>
                        </select>
                        <span className="return-header">Comments (optional):</span>
                        <textarea
                          name="return-comments"
                          id="return-comments"
                          cols="30"
                          rows="5"
                          maxLength={200}
                          onChange={handleTextAreaChange}
                          value={textAreaValue}
                          className="return-comments-text-area"
                        />
                        <p style={{ fontSize: "14px", color: "#565959", marginBottom: "0" }}>
                          {charactersRemaining} characters remaining.
                        </p>
                        <p style={{ fontSize: "14px", color: "#565959", marginTop: "20px", marginBottom: "30px" }}>
                          NOTE: We aren't able to offer policy exceptions in response to comments. Do not include
                          personal information as these comments may be shared with external service providers to
                          identify product defects.
                        </p>
                        <Button onClick={onContinuePress} className="mobile-full-width">
                          Continue
                        </Button>
                      </ReturnItemContainer>
                    </OrderProductContainer>
                  </ContentContainer>
                </OrderItemContainer>
              </OrdersContainer>
            </div>
          </ContentContainer>
        </ContentContainer>
      </OrdersContent>
    </Container>
  );
};

export default ReturnReason;
