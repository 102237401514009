import styled from "styled-components";
import { MobileBreakpoint } from "../config/constants";
import PropTypes from "prop-types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    font-size: 54px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }

  .smaller-mb {
    margin-bottom: 20px;
  }

  .small-mb {
    margin-bottom: 25px;
  }

  .medium-mb {
    margin-bottom: 40px;
  }

  .horizontal-center {
    margin: 0 auto;
  }

  .secondary-button {
    background-color: field;
    color: #6fada8;
    border: 1px solid #6fada8;
  }

  .secondary-button:hover {
    background-color: #ededed;
  }

  .hyperlink-button {
    border: none;
    background: none;
    font-weight: normal;
    border-radius: 0;
    padding: 0;
    color: #3573b1;
    text-decoration: underline;
    cursor: pointer;
  }

  .hyperlink-button:hover {
    text-decoration: none;
  }

  .button-disabled {
    background-color: "#ededed";
    color: #d3d3d3;
  }

  .normal-link-color {
    color: #3573b1;
  }

  .inline-text-link {
    color: #3573b1;
    text-decoration: underline;
  }

  .inline-text-link:hover {
    text-decoration: none;
  }

  .mobile-only {
    display: none;
  }

  .mobile-only-flex {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  &.gray-white-background {
    background-color: #f7f7f7;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .mobile-reverse {
      flex-direction: column-reverse;
    }

    .mobile-only {
      display: block;
    }

    .mobile-only-flex {
      display: flex;
    }

    .desktop-only {
      display: none;
    }

    &.gray-white-background {
      background-color: #ffffff;
    }

    .mobile-full-width {
      width: 100%;
      max-width: 1200px;
    }

    .mobile-column {
      display: flex;
      flex-direction: column;
    }
  }
`;

const ContentContainerStyled = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  gap: 40px;

  // Makes content go in a row
  &.horizontal {
    display: flex;
    gap: 30px;

    @media screen and (max-width: ${MobileBreakpoint}) {
      flex-direction: column;
    }
  }

  // Takes up the entire content width (useful for content that doesn't reach it)
  &.full {
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    gap: 20px;
  }
`;

const ContentContainerProps = {
  disableGutters: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

export const ContentContainer = ({ disableGutters = false, children, style, ...props }) => (
  <ContentContainerStyled
    {...props}
    style={{
      ...style,
      paddingLeft: disableGutters ? 0 : 20,
      paddingRight: disableGutters ? 0 : 20,
    }}
  >
    {children}
  </ContentContainerStyled>
);

ContentContainer.propTypes = ContentContainerProps;
