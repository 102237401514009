import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button/Button";
import { Container, ContentContainer } from "../components/WebLayoutComponents";
import { MobileBreakpoint } from "../config/constants";
import { orders } from "../orders-mock";
import DropdownChevron from "../static/img/chevron-down-arrow.png";

const HeaderContainer = styled(ContentContainer)`
  .header {
    font-size: 54px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .header {
      font-size: 46px;
    }
  }
`;

const OrdersContent = styled.div`
  max-width: 1237px;
  margin: 0 auto;
  width: 100%;
`;

const OrdersContainer = styled.div`
  .order-delivery-date {
    font-weight: bold;
    font-size: 30px;
  }
`;

const OrderDropdownContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  width: fit-content;
  height: 65px;
  padding: 20px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    width: 100%;

    span {
      text-align: center;
      width: 100%;
    }
  }
`;

const OrderItemContainer = styled.div`
  width: 100%;
`;

const OrderProductContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .product-image {
    max-width: 205px;
    max-height: 206px;
    min-width: 78px;
    min-height: 78px;
    border-radius: 16px;
  }

  .product-title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 1rem;
  }

  .product-subtitle {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    .product-image {
      max-width: 78px;
    }

    .product-title {
      font-size: 26px;
      margin-left: 20px;
    }

    .product-subtitle {
      font-size: 18px;
      margin-top: 10px;
    }
  }
`;

const OrderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  margin-top: 10px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    margin-left: 0;
  }
`;

const OrderButtonContainer = styled.div`
  display: flex;

  * {
    margin-right: 30px;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    flex-direction: column;

    * {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
`;

const Orders = () => {
  const navigate = useNavigate();

  function viewItemPress(productId) {
    navigate(`/product/${productId.toString()}`);
  }

  function requestReturnPress(item) {
    sessionStorage.setItem("return-item", JSON.stringify(item));
    navigate("/return/reason");
  }

  return (
    <Container className="gray-white-background">
      <OrdersContent>
        <HeaderContainer className="horizontal full">
          <h1 className="header">Orders</h1>
        </HeaderContainer>
        <ContentContainer className="horizontal full" style={{ justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              gap: 10,
              backgroundColor: "#F3F3F3",
              borderRadius: 10,
              padding: 5,
            }}
          >
            <Button variant="contained" color="secondary" className="mobile-full-width">
              Orders
            </Button>
            <Button variant="text" className="desktop-only lighter-text">
              Not Yet Shipped
            </Button>
            <Button variant="text" className="desktop-only lighter-text">
              Cancelled Orders
            </Button>
          </div>
          <OrderDropdownContainer>
            <span style={{ fontWeight: "600" }}>Past 3 Months</span>
            <img
              src={DropdownChevron}
              alt="dropdown arrow"
              style={{ width: "18px", height: "11px", margin: "auto 0 auto 10px" }}
            />
          </OrderDropdownContainer>
        </ContentContainer>
        <ContentContainer className="full" style={{ marginTop: "30px" }}>
          <ContentContainer
            disableGutters
            className="full"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #CDCDCD",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <div style={{ backgroundColor: "#FFFFFF" }}>
              <OrdersContainer style={{ paddingTop: "20px" }}>
                {orders.map((order, index) => {
                  return (
                    <OrderItemContainer key={order.productInfo.id}>
                      {index !== 0 && <hr style={{ border: "1px solid #CDCDCD" }} />}
                      <ContentContainer>
                        <span className="order-delivery-date">Delivered {order.deliveredDate}</span>
                        <OrderProductContainer>
                          <img
                            src={order.productInfo.source}
                            alt={order.productInfo.alt}
                            className="product-image desktop-only"
                          />
                          <OrderDetailContainer>
                            <div style={{ display: "flex" }}>
                              <img
                                src={order.productInfo.source}
                                alt={order.productInfo.alt}
                                className="product-image mobile-only"
                              />
                              <span className="product-title">{order.productInfo.title}</span>
                            </div>
                            <span className="product-subtitle">
                              Return or replace items: Eligible through {order.returnByDate}
                            </span>
                            <OrderButtonContainer>
                              <Button onClick={() => viewItemPress(order.productInfo.id)}>Buy it Again</Button>
                              <Button className="secondary-button" onClick={() => viewItemPress(order.productInfo.id)}>
                                View Your Item
                              </Button>
                              <Button className="secondary-button" onClick={() => requestReturnPress(order)}>
                                Request a Return
                              </Button>
                            </OrderButtonContainer>
                          </OrderDetailContainer>
                        </OrderProductContainer>
                      </ContentContainer>
                    </OrderItemContainer>
                  );
                })}
              </OrdersContainer>
            </div>
          </ContentContainer>
        </ContentContainer>
      </OrdersContent>
    </Container>
  );
};

export default Orders;
