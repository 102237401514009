import { z } from "zod";

export const formSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, { message: "First Name is required." }),
  lastName: z
    .string()
    .trim()
    .min(1, { message: "Last Name is required." }),
  email: z
    .string()
    .trim()
    .email(),
  streetAddress: z
    .string()
    .trim()
    .min(1, { message: "Address is required." }),
  country: z.string().trim(),
  aptNumber: z
    .string()
    .regex(/(^\d+$|^$)/, { message: "Use numbers only" })
    .optional(),
  city: z
    .string()
    .trim()
    .min(1, { message: "City is required." }),
  state: z
    .string()
    .trim()
    .min(2, { message: "State must be selected" }),
  zip: z
    .string()
    .trim()
    .min(1, { message: "Zip Code is required" })
    .length(5, { message: "Must be a valid 5 digit zip" }),
  phone: z
    .string()
    .regex(/^\(\d{3}\) \d{3}-\d{4}$/, {
      message: "Invalid phone number format",
    })
    .min(14, { message: "Phone number must be complete" }),
  cardNumber: z
    .string()
    .trim()
    .min(1, { message: "Card number is required." })
    .length(19, { message: "Card number should be 16 digits long." }),
  expirationDate: z
    .string()
    .trim()
    .min(1, { message: "Expiration Date required." })
    .regex(/^\d{2}\/\d{2}$/, {
      message: "Not valid. Format needs to be MM/YY",
    }),
  securityCode: z
    .string()
    .trim()
    .min(3, { message: "Code must be 3 characters." }),
  name: z
    .string()
    .trim()
    .min(1, { message: "Name is required." }),
});

export const firstStepSchema = z.object({
  firstName: formSchema.shape.firstName,
  lastName: formSchema.shape.lastName,
  email: formSchema.shape.email,
  streetAddress: formSchema.shape.streetAddress,
  country: formSchema.shape.country,
  aptNumber: formSchema.shape.aptNumber,
  city: formSchema.shape.city,
  state: formSchema.shape.state,
  zip: formSchema.shape.zip,
  phone: formSchema.shape.phone,
});
