// components/Header
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import PropTypes from "prop-types";
import TopBarLogo from "../static/img/authentic-adventures-logo.svg";
import Button from "./Button/Button";
import CartIcon from "./icons/Cart";
import { useCart } from "../providers/CartContext";
import Popper from "./Popper/Popper";
import CartPopper from "./CartPopper/CartPopper";
import { HamburgerIcon } from "./icons/HamburgerIcon";
import ChevronIcon from "./icons/Chevron";
import { MobileBreakpoint } from "../config/constants";

const HeaderProps = {
  checkout: PropTypes.bool,
  returns: PropTypes.bool,
};

const HeaderContainer = styled.div`
  display: flex;

  .desktop-only {
    display: block;
  }

  .desktop-only-flex {
    display: flex;
  }

  .mobile-only {
    display: none;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    padding: 5px;
    border-bottom: 3px solid #6fada8;

    .desktop-only {
      display: none;
    }

    .desktop-only-flex {
      display: none;
    }

    .mobile-only {
      display: block;
    }
  }
`;

const HeaderInner = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 1600px;
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin: auto 0 auto auto;
  }

  .header-logo {
    max-width: 174px;
    margin: 0 auto 0 0;
  }

  .header-link {
    font-size: 18px;
  }

  .header-link:hover {
    text-decoration: none;
  }

  .active-header-link {
    font-size: 18px;
    border-bottom: 4px solid #6fada8;
  }

  .active-header-link:hover {
    text-decoration: none;
  }

  .desktop-margin-left {
    margin-left: auto;
  }

  @media screen and (max-width: ${MobileBreakpoint}) {
    justify-content: space-between;

    .header-logo {
      margin: 0 auto;
    }

    .desktop-margin-left {
      margin: 0;
    }
  }
`;

const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CartTotal = styled.span`
  position: absolute;
  color: red;
  font-size: 0.594rem;
  margin-top: -4px;
  margin-left: -4px;
  font-family: "Helvetica Neue Bold";
`;

const NavigationLinks = styled.div`
  display: flex;
  margin-left: 40px;
`;

const Curtain = styled.div`
  &.curtain {
    background: #ededed;
    position: fixed;
    top: 102px;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 150;
  }

  .curtain-header {
    display: flex;
    height: 53px;
    background: #6fada8;
    justify-content: center;
    align-items: center;
    border-top: 2px solid black;

    span {
      font-weight: bold;
      color: white;
      font-size: 18px;
      text-align: center;
    }
  }

  .curtain-links {
    display: flex;
    flex-direction: column;
  }

  .curtain-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    color: black;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    background: #ededed;
    border-bottom: 1px solid #6fada8;
    padding: 0 10px;
    box-sizing: border-box;
    &:hover {
      background: #d8d8d8;
    }
    svg {
      transform: rotate(-90deg);
    }
    span {
      color: #6fada8;
    }
  }
`;
const CurtainHeader = styled.div``;
const CurtainLinks = styled.div``;
const CurtainLink = styled(Link)``;

function Header({ checkout, returns }) {
  const { cart } = useCart();
  const [clicked, setClicked] = useState(false);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [curtainOpen, setCurtainOpen] = useState(false);
  const pathName = window.location.pathname;

  const curtainLinkPress = () => {
    setCurtainOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setClicked(false);
      }
    };

    const handleEscapeKeyPress = event => {
      if (event.key === "Escape") {
        setClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [menuRef, buttonRef]);

  const toggleMenu = () => {
    setClicked(!clicked);

    if (!clicked && menuRef.current && menuRef.current.updatePosition) {
      // If the menu is being opened and menuRef is available, delay and then update the position
      setTimeout(() => {
        menuRef.current.updatePosition(buttonRef.current);
      }, 0);
    }
  };

  useLayoutEffect(() => {
    const calculateMenuPosition = () => {
      if (buttonRef.current && menuRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        const leftPosition = rect.left + rect.width - menuRef.current.offsetWidth;

        menuRef.current.style.left = `${leftPosition}px`;
        menuRef.current.style.top = `${rect.bottom + scrollTop}px`;
      }
    };

    calculateMenuPosition();
  }, [buttonRef, cart.items.length, clicked]);

  useEffect(() => {
    // Calculate total quantity of items in the cart
    let total = 0;

    cart?.items?.forEach(item => {
      total += item.quantity;
    });

    setTotalQuantity(total);
  }, [cart.items]);

  return (
    <HeaderContainer>
      <HeaderInner>
        <Button iconButton onClick={() => setCurtainOpen(!curtainOpen)} className="mobile-only">
          <HamburgerIcon />
        </Button>
        <Link to="/">
          <img src={TopBarLogo} alt="Authentik Adventures Logo" className="header-logo" style={{ maxWidth: "174px" }} />
        </Link>
        <NavigationLinks className="desktop-only">
          <Link
            to="/"
            className={pathName === "/" ? "active-header-link" : "header-link"}
            style={{ marginRight: "30px" }}
          >
            Home
          </Link>
          <Link to="/orders" className={pathName === "/orders" ? "active-header-link" : "header-link"}>
            Orders
          </Link>
        </NavigationLinks>
        {checkout && (
          <div style={{ display: "flex" }} className="desktop-margin-left">
            <p style={{ color: "#3573B1" }}>Hi User</p>
          </div>
        )}
        {!checkout && (
          <div style={{ display: "flex", gap: "1rem" }} className="desktop-margin-left">
            <ButtonStyled
              iconButton
              onClick={toggleMenu} // Change event handler to onClick
              ref={buttonRef}
            >
              <CartIcon sx={{ width: 30 }} />
              {totalQuantity > 0 && <CartTotal>{totalQuantity}</CartTotal>}
            </ButtonStyled>
            {clicked && (
              <Popper
                id="cartQuickView"
                ref={menuRef}
                menuPopper={false}
                closeButton
                handleClose={toggleMenu}
                style={{ marginLeft: "25px" }}
              >
                {cart.items.length === 0 && <div>You have no items in your shopping cart.</div>}
                {cart.items.length > 0 && <CartPopper handleClose={toggleMenu} />}
              </Popper>
            )}
          </div>
        )}
        {curtainOpen && (
          <Curtain className="curtain">
            <CurtainHeader className="curtain-header">
              <span>Authentik Adventures</span>
            </CurtainHeader>
            <CurtainLinks className="curtain-links">
              <CurtainLink className="curtain-link" to="/" onClick={curtainLinkPress}>
                <span>Home Page</span>
                <ChevronIcon variant="down" />
              </CurtainLink>
              <CurtainLink className="curtain-link" to="/checkout" onClick={curtainLinkPress}>
                <span>Checkout</span>
                <ChevronIcon variant="down" />
              </CurtainLink>
              <CurtainLink className="curtain-link" to="/orders" onClick={curtainLinkPress}>
                <span>Orders</span>
                <ChevronIcon variant="down" />
              </CurtainLink>
            </CurtainLinks>
          </Curtain>
        )}
      </HeaderInner>
    </HeaderContainer>
  );
}

Header.propTypes = HeaderProps;

export default Header;
