import PropTypes from 'prop-types';

const PinterestIconProps = {
    sx: PropTypes.object,
};

const PinterestIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M95,50c0,24.9-20.2,45-45,45c-4.7,0-9.1-0.7-13.3-2c1.9-3,4.6-7.9,5.6-11.8c0.6-2.1,2.8-10.7,2.8-10.7c1.4,2.8,5.7,5.2,10.3,5.2c13.6,0,23.4-12.5,23.4-28c0-14.9-12.2-26-27.8-26c-19.5,0-29.8,13-29.8,27.2c0,6.6,3.5,14.8,9.2,17.5c0.9,0.4,1.3,0.2,1.5-0.6c0.1-0.6,0.9-3.7,1.3-5.1c0.1-0.4,0.1-0.9-0.3-1.3c-1.9-2.3-3.3-6.4-3.3-10.3c0-9.9,7.5-19.5,20.3-19.5c11.1,0,18.8,7.5,18.8,18.3c0,12.2-6.2,20.6-14.2,20.6c-4.4,0-7.7-3.7-6.7-8.2c1.3-5.4,3.7-11.2,3.7-15c0-3.4-1.9-6.3-5.7-6.3c-4.5,0-8.2,4.7-8.2,10.9c0,4,1.3,6.7,1.3,6.7S34.6,75.5,33.8,79c-0.9,3.9-0.6,9.4-0.1,12.9C16.9,85.4,5,69.1,5,50 C5,25.1,25.2,5,50,5C74.9,4.9,95.1,25.1,95,50z' />
        </svg>
    );
};

PinterestIcon.propTypes = PinterestIconProps;

export default PinterestIcon;

