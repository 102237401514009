import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Button from "../Button/Button";
import CheckIcon from "../icons/Check";
import CloseIcon from "../icons/Close";
import InfoIcon from "../icons/Info";

// Styled components for the alert
const AlertBox = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: ${({ type }) => {
    switch (type) {
      case "success":
        return "#d4edda";
      case "info":
        return "#cce5ff";
      case "warning":
        return "#FEF7DB";
      case "error":
        return "#f8d7da";
      default:
        return "#ffffff";
    }
  }};
  color: ${({ type }) => {
    switch (type) {
      case "success":
        return "#155724";
      case "info":
        return "#0c5460";
      case "warning":
        return "#856404";
      case "error":
        return "#721c24";
      default:
        return "#000000";
    }
  }};
  border: 1px solid
    ${({ type }) => {
      switch (type) {
        case "success":
          return "#d4edda";
        case "info":
          return "#cce5ff";
        case "warning":
          return "#F5E19C";
        case "error":
          return "#f8d7da";
        default:
          return "#ffffff";
      }
    }};
`;

const Icon = styled.span`
  margin-right: 10px;
`;

// Map of types to icons
const iconMap = {
  success: <CheckIcon variant="circle-outline" />,
  info: <InfoIcon />,
  warning: <InfoIcon />,
  error: <InfoIcon />,
};

// Alert component
const Alert = ({ type, icon, children, disableIcon, handleCloseClick }) => {
  // Determine which icon to use
  const alertIcon = icon || iconMap[type];
  const iconColor = type
    ? {
        success: "#155724",
        info: "#0c5460",
        warning: "#856404",
        error: "#721c24",
      }[type]
    : "#000000";

  return (
    <AlertBox type={type}>
      {/* Render the icon only if it's not explicitly disabled */}
      {!disableIcon && alertIcon && (
        <Icon>
          {React.cloneElement(alertIcon, {
            sx: { fill: iconColor, display: "flex" },
          })}
        </Icon>
      )}
      {children}
      <Button iconButton onClick={handleCloseClick} style={{ marginLeft: "auto" }}>
        <CloseIcon sx={{ maxWidth: "16px" }} />
      </Button>
    </AlertBox>
  );
};

// PropTypes for type checking
Alert.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning", "error"]),
  icon: PropTypes.node, // Allow passing custom icon
  children: PropTypes.node.isRequired,
  disableIcon: PropTypes.bool, // Prop to disable the icon
  handleCloseClick: PropTypes.func.isRequired,
};

// Default props
Alert.defaultProps = {
  type: "success", // Default type is 'success'
  icon: undefined, // Allow icon to default based on type
  disableIcon: false, // Default to not disable the icon
};

export default Alert;
