import { getProduct } from "./products-mock";

export const orders = [
  {
    deliveredDate: "July 21",
    returnByDate: "August 14, 2023",
    productInfo: getProduct(1111),
  },
  {
    deliveredDate: "July 21",
    returnByDate: "August 14, 2023",
    productInfo: getProduct(1119),
  },
];
