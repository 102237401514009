// OrderStatus.js

import { useState } from "react";
import { Container, ContentContainer } from "../components/WebLayoutComponents";
import Button from "../components/Button/Button";
import styled from "styled-components";
import CardContainer from "../components/CardContainer/CardContainer";
import Card from "../components/Card/Card";
import Calendar from "react-calendar";
import { getCurrentDateInfo, getFutureMonthAndDay } from "../utils/date";
import StarIcon from "../components/icons/Star";
import FacebookIcon from "../components/icons/Facebook";
import InstagramIcon from "../components/icons/Instagram";
import PinterestIcon from "../components/icons/Pinterest";
import TwitterIcon from "../components/icons/Twitter";
import YouTubeIcon from "../components/icons/YouTube";
import QRCode from "../static/img/qrcode.png";
import Activities from "../static/img/activities.png";
import USPSLogo from "../static/img/usps-logo.png";
import { products } from "../products-mock";
import LockersIcon from "../components/icons/Lockers";

const DateContainer = styled.div`
  color: #6fada8;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Helvetica Neue Bold";
  width: 70px;
`;

const TimeContainer = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Helvetica Neue Bold";
  width: 70px;
`;

const StatusTitle = styled.div`
  font-size: 14px;
`;

const LocationContainer = styled.div`
  font-size: 14px;
`;

const TrackingTitle = styled.div`
  font-size: 14px;
  font-family: "Helvetica Neue Bold";
`;

const TrackingNumber = styled.div`
  font-size: 14px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px auto;
`;

const StatusCircle = styled.div`
  width: 12px;
  height: 12px;
  background-color: #000000;
  border-radius: 50%;
`;

const StatusDivider = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #000000;
`;

const OrderStatus = () => {
  const [ratings, setRatings] = useState([false, false, false, false, false]);

  const handleStarClick = index => {
    const updatedRatings = ratings.map((value, i) => i <= index);
    setRatings(updatedRatings);
  };

  const dateInfo = getCurrentDateInfo();
  const futureMonthAndDay = getFutureMonthAndDay();

  return (
    <Container>
      <ContentContainer className="horizontal full">
        <ContentContainer disableGutters className="horizontal full">
          <CardContainer style={{ gap: "2rem" }}>
            <Card
              title="Delivered to Locker"
              content={
                <ContentContainer
                  disableGutters
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    gap: 0,
                    flexDirection: "column",
                  }}
                >
                  <h4
                    style={{
                      fontSize: 22,
                      textTransform: "uppercase",
                      fontFamily: "Helvetica Neue Bold",
                      marginBottom: 0,
                    }}
                  >
                    {dateInfo.currentDayOfWeek}
                  </h4>
                  <h5
                    style={{
                      fontSize: 18,
                      textTransform: "uppercase",
                      fontFamily: "Helvetica Neue Regular",
                      margin: 0,
                    }}
                  >
                    {dateInfo.currentMonth}
                  </h5>
                  <div
                    style={{
                      fontSize: 128,
                      fontFamily: "Helvetica Neue Medium",
                      margin: "20px 0",
                      color: "#6FADA8",
                    }}
                  >
                    {dateInfo.currentDayOfMonth}
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: 22,
                        fontFamily: "Helvetica Neue Regular",
                        margin: "0 40px 20px",
                        textAlign: "center",
                      }}
                    >
                      Available for pick at the locker
                      <br />
                      until {futureMonthAndDay}
                    </p>
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <Calendar showNeighboringMonth={false} showNavigation={false} />
                  </div>
                </ContentContainer>
              }
            />
            <Card
              title="Help us improve"
              content={
                <ContentContainer
                  disableGutters
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 0,
                    alignItems: "center",
                  }}
                >
                  <p>Rate Your Shipping Experience</p>
                  <ContentContainer
                    disableGutters
                    className="horizontal full"
                    style={{
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: 20,
                    }}
                  >
                    {ratings.map((active, index) => (
                      <Button key={index} iconButton onClick={() => handleStarClick(index)}>
                        <StarIcon sx={{ width: 36 }} variant={active ? "full" : "empty"} />
                      </Button>
                    ))}
                  </ContentContainer>
                </ContentContainer>
              }
            />
          </CardContainer>
        </ContentContainer>
        <ContentContainer disableGutters className="horizontal full">
          <CardContainer style={{ alignItems: "flex-start" }}>
            <Card
              title="Delivery Status"
              content={
                <ContentContainer
                  disableGutters
                  className="full"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    gap: 0,
                    flexDirection: "column",
                  }}
                >
                  <h3
                    style={{
                      fontSize: 28,
                      textTransform: "uppercase",
                      fontFamily: "Helvetica Neue Medium",
                      marginBottom: 0,
                      color: "#218748",
                    }}
                  >
                    AVAILABLE FOR PICK-UP
                  </h3>
                  <div
                    style={{
                      width: 117,
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <img src={QRCode} width="100%" alt="qrcode" />
                  </div>
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Helvetica Neue Regular",
                      marginTop: 0,
                      marginBottom: 30,
                      textAlign: "center",
                    }}
                  >
                    Access Code: 5C35D1
                  </p>
                  <Button variant="contained">Download</Button>
                  <StatusContainer>
                    <StatusCircle />
                    <StatusDivider />
                    <StatusCircle />
                    <StatusDivider />
                    <LockersIcon sx={{ width: 70 }} />
                  </StatusContainer>
                  <h4
                    style={{
                      fontSize: 22,
                      textTransform: "uppercase",
                      fontFamily: "Helvetica Neue Medium",
                    }}
                  >
                    SHIPMENT HISTORY
                  </h4>
                  <hr style={{ width: "100%" }} />
                  <ContentContainer
                    disableGutters
                    className="horizontal full"
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <DateContainer>JAN 31</DateContainer>
                      <TimeContainer>10:10 AM</TimeContainer>
                    </div>
                    <div>
                      <StatusTitle>Delivered to Locker</StatusTitle>
                      <LocationContainer>Avondale Estates</LocationContainer>
                    </div>
                  </ContentContainer>

                  <hr style={{ width: "100%" }} />

                  <ContentContainer
                    disableGutters
                    className="horizontal full"
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <DateContainer>JAN 30</DateContainer>
                      <TimeContainer>10:10 AM</TimeContainer>
                    </div>
                    <div>
                      <StatusTitle>
                        Picked up by Shipping Partner,
                        <br />
                        USPS Awaiting item
                      </StatusTitle>
                      <LocationContainer>Roanoke, IN</LocationContainer>
                    </div>
                  </ContentContainer>

                  <hr style={{ width: "100%" }} />

                  <ContentContainer
                    disableGutters
                    className="horizontal full"
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <DateContainer>JAN 29</DateContainer>
                      <TimeContainer>10:10 AM</TimeContainer>
                    </div>
                    <div>
                      <StatusTitle>
                        Picked up by Shipping Partner,
                        <br />
                        USPS Awaiting item
                      </StatusTitle>
                      <LocationContainer>Avondale Estates</LocationContainer>
                    </div>
                  </ContentContainer>

                  <hr style={{ width: "100%" }} />

                  <div
                    style={{
                      width: 265,
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <img src={USPSLogo} alt="USPS" width="100%" />
                  </div>

                  <TrackingTitle>TRACKING NUMBER</TrackingTitle>
                  <TrackingNumber style={{ marginBottom: 20 }}>WER200006034</TrackingNumber>
                </ContentContainer>
              }
            />
          </CardContainer>
        </ContentContainer>

        <ContentContainer disableGutters className="horizontal full">
          <CardContainer style={{ gap: "2rem" }}>
            <Card
              content={
                <ContentContainer
                  disableGutters
                  className="full"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    gap: 0,
                    flexDirection: "column",
                    paddingBottom: 20,
                  }}
                >
                  <div style={{ margin: "-16px -16px 0" }}>
                    <img src={Activities} alt="activities in your area" width="100%" />
                  </div>
                  <h3
                    style={{
                      fontSize: 31,
                      fontFamily: "Helvetica Neue Regular",
                    }}
                  >
                    Hikes in your area
                  </h3>
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Helvetica Neue Regular",
                      marginTop: 0,
                      marginBottom: 30,
                    }}
                  >
                    Great hikes in your area
                  </p>
                  <Button variant="contained">Hike Now</Button>
                </ContentContainer>
              }
            />
            <Card
              title="Hike along with us on"
              content={
                <ContentContainer
                  disableGutters
                  className="horizontal full"
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <FacebookIcon sx={{ fill: "#3B579D", width: 32 }} />
                  <InstagramIcon sx={{ width: 32 }} />
                  <PinterestIcon sx={{ fill: "#CB2027", width: 32 }} />
                  <TwitterIcon sx={{ fill: "#1DA1F2", width: 32 }} />
                  <YouTubeIcon sx={{ fill: "#DD2824", width: 32 }} />
                </ContentContainer>
              }
            />
          </CardContainer>
        </ContentContainer>
      </ContentContainer>

      <ContentContainer>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        <h3>You may also like</h3>
        <ContentContainer className="horizontal full" disableGutters style={{ marginTop: 20, marginBottom: 20 }}>
          <CardContainer>
            {products.map(product => (
              <Card
                key={product.id}
                src={product.source}
                alt={product.alt}
                title={product.title}
                price={product.price}
                link={`/product/${product.id}`}
                disableActions
                variant="product"
              />
            ))}
          </CardContainer>
        </ContentContainer>
      </ContentContainer>
    </Container>
  );
};

export default OrderStatus;
