import { Container } from "../components/WebLayoutComponents";
import styled from "styled-components";
import HikingTrail from "../static/img/HikingTrail.png";
import Button from "../components/Button/Button";
import qrCode from "../static/img/qrcode.png";
import DeliveryProgressBar from "../static/img/DeliveryBar.PNG";
import UspsLogo from "../static/img/usps-logo.png";
import StarIcon from "../static/img/StarIcon.png";
import { MobileBreakpoint } from "../config/constants";

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1184px;
  margin: 20px auto;
`;

const DashboardLayout = styled.div`
  display: flex;
  gap: 15px;

  @media screen and (max-width: ${MobileBreakpoint}) {
    flex-direction: column;
  }
`;

const DashboardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  max-width: 385px;
  min-width: 385px;
  text-align: center;

  &.card-margin-bottom {
    margin-bottom: 30px;
  }

  .pick-up-status {
    color: #218748;
    font-size: 28px;
  }
`;

const CardTopBanner = styled.div`
  background-color: #6fada8;
  text-align: center;
  max-height: 60px;
  min-height: 60px;
  color: #ffffff;
  padding: 15px;
  vertical-align: middle;
  font-size: 22px;
  font-weight: bold;

  &.left-align {
    text-align: left;
  }
`;

const CardContent = styled.div`
  padding: 30px;

  .weekday {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
  }

  .month {
    font-size: 18px;
    margin: 0;
  }

  .day {
    color: #6fada8;
    font-size: 128px;
  }

  .qr-code {
    max-width: 152px;
  }

  .delivery-progress-bar {
    max-width: 335px;
    margin: 30px 0;
  }

  .usps-logo {
    max-width: 267px;
    margin: 28px 0;
  }

  .calendar {
    margin-top: 30px;
    width: 100%;
    border-spacing: 20px;
  }
`;

const ShippingEvent = styled.div`
  display: flex;
  padding: 20px 0;
  border-top: 0.25px solid #707070;

  &.last {
    border-bottom: 0.25px solid #707070;
  }

  .shipping-event-time-day {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-right: 25px;
    text-align: left;

    * {
      margin: 0;
    }
  }

  .shipping-event-details {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    p {
      margin: 0;
      text-align: left;
    }
  }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 19px;
  line-height: 19px;

  img {
    max-height: 32px;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 auto 40px;
  width: fit-content;
`;

const OrderStatusDashboard = () => {
  return (
    <Container>
      <PageContent>
        <DashboardLayout>
          <DashboardColumn>
            <Card className="card-margin-bottom">
              <CardTopBanner>Delivered to Locker</CardTopBanner>
              <CardContent>
                <h5 className="weekday">Wednesday</h5>
                <span className="month">January</span>
                <h1 className="day">31</h1>
                <b>Available for pick at the locker until February 5th</b>
                <table className="calendar desktop-only">
                  <tbody>
                    <tr>
                      <td>S</td>
                      <td>M</td>
                      <td>T</td>
                      <td>W</td>
                      <td>T</td>
                      <td>F</td>
                      <td>S</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>8</td>
                      <td>9</td>
                      <td>10</td>
                      <td>11</td>
                      <td>12</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>15</td>
                      <td>16</td>
                      <td>17</td>
                      <td>18</td>
                      <td>19</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>22</td>
                      <td>23</td>
                      <td>24</td>
                      <td>25</td>
                      <td>26</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td>29</td>
                      <td>30</td>
                      <td>31</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
            <Card className="desktop-only">
              <CardTopBanner className="left-align">Help us improve</CardTopBanner>
              <p>Rate Your Shipping Experience</p>
              <RatingContainer>
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
              </RatingContainer>
            </Card>
          </DashboardColumn>
          <DashboardColumn>
            <Card className="card-margin-bottom">
              <CardTopBanner>Delivery Status</CardTopBanner>
              <CardContent style={{ padding: "24px" }}>
                <p className="pick-up-status">AVAILABLE FOR PICK-UP</p>
                <img src={qrCode} alt="QR code" className="qr-code" />
                <p>Access Code: 5C35D1</p>
                <Button>Download</Button>
                <img src={DeliveryProgressBar} alt="Package shipment progress" className="delivery-progress-bar" />
                <p>Shipment History</p>
                <ShippingEvent>
                  <div className="shipping-event-time-day">
                    <b>JAN 31</b>
                    <p>10:10 AM</p>
                  </div>
                  <div className="shipping-event-details">
                    <p>Delivered to Locker</p>
                    <p>Avondale Estates</p>
                  </div>
                </ShippingEvent>
                <ShippingEvent>
                  <div className="shipping-event-time-day">
                    <b>JAN 30</b>
                    <p>10:10 AM</p>
                  </div>
                  <div className="shipping-event-details">
                    <p>Picked up by Shipping Partner, IN</p>
                    <p>USPS Awaiting Item</p>
                    <p>Roanoke, IN</p>
                  </div>
                </ShippingEvent>
                <ShippingEvent className="last">
                  <div className="shipping-event-time-day">
                    <b>JAN 29</b>
                    <p>10:10 AM</p>
                  </div>
                  <div className="shipping-event-details">
                    <p>Picked up by Shipping Partner, IN</p>
                    <p>USPS Awaiting Item</p>
                    <p>Roanoke, IN</p>
                  </div>
                </ShippingEvent>
                <img src={UspsLogo} alt="USPS Logo" className="usps-logo" />
                <b style={{ fontSize: "14px" }}>TRACKING NUMBER</b>
                <p style={{ margin: "0" }}>WER200006034</p>
              </CardContent>
            </Card>
            <Card className="mobile-only">
              <CardTopBanner className="left-align">Help us improve</CardTopBanner>
              <p>Rate Your Shipping Experience</p>
              <RatingContainer>
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
                <img src={StarIcon} alt="rating star" style={{ width: "37px" }} />
              </RatingContainer>
            </Card>
          </DashboardColumn>
          <DashboardColumn>
            <Card className="card-margin-bottom desktop-only">
              <img
                src={HikingTrail}
                alt="Hiking trail with a hiking sign with a background of endless green, hills, and mountains."
              />
              <CardContent>
                <b>Hikes in your area</b>
                <p>Great hikes in your area</p>
                <Button>Hike Now</Button>
              </CardContent>
            </Card>
            <Card className="card-margin-bottom">
              <CardTopBanner className="left-align">Hike along with us on</CardTopBanner>
              <CardContent>
                <SocialContainer>
                  <div>
                    <a
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href="https://www.facebook.com/USPS?rf=108501355848630"
                      rel="noreferrer"
                    >
                      <img
                        alt="Facebook social media icon."
                        src="https://www.usps.com/global-elements/footer/images/facebook_logo.png"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href="https://www.instagram.com/uspostalservice/?hl=en"
                      rel="noreferrer"
                    >
                      <img
                        alt="Instagram social media icon."
                        src="https://www.usps.com/global-elements/footer/images/instagram_logo.png"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href="https://www.pinterest.com/uspsstamps/"
                      rel="noreferrer"
                    >
                      <img
                        alt="Pinterest social media icon."
                        src="https://www.usps.com/global-elements/footer/images/pinterest_logo.png"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href="https://twitter.com/usps"
                      rel="noreferrer"
                    >
                      <img
                        alt="X social media icon."
                        src="https://www.usps.com/global-elements/footer/images/x_logo.png"
                      />
                    </a>
                  </div>

                  <div>
                    <a
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href="https://www.youtube.com/usps"
                      rel="noreferrer"
                    >
                      <img
                        alt="Youtube social media icon."
                        src="https://www.usps.com/global-elements/footer/images/youtube_logo.png"
                      />
                    </a>
                  </div>
                </SocialContainer>
              </CardContent>
            </Card>
          </DashboardColumn>
        </DashboardLayout>
      </PageContent>
    </Container>
  );
};

export default OrderStatusDashboard;
