import PropTypes from 'prop-types';

const CloseIconProps = {
    sx: PropTypes.object,
};

const CloseIcon = (props) => {
    const { sx, ...reducedProps } = props;
    const defaultSize = 24;

    return (
        <svg
            {...reducedProps}
            viewBox='0 0 100 100'
            style={{ width: defaultSize, ...sx }} // Apply the transform style directly
        >
            <path d='M96.1,87.8c2.8,2.8,2.8,7.5,0,10.4c-0.9,0.9-2.8,1.9-4.7,1.9c-1.9,0-3.8-0.9-4.7-1.9L49.1,60.5L11.4,98.1 c-0.9,0.9-2.8,1.9-4.7,1.9S2.9,99.1,2,98.1c-2.8-2.8-2.8-7.5,0-10.4l37.6-37.6L2,12.5C-0.8,9.6-0.8,4.9,2,2.1s7.5-2.8,10.4,0 L50,39.8L87.6,2.1c2.8-2.8,7.5-2.8,10.4,0s2.8,7.5,0,10.4L60.4,50.1L96.1,87.8z' />
        </svg>
    );
};

CloseIcon.propTypes = CloseIconProps;

export default CloseIcon;

