import React from "react";
import styled, { css } from "styled-components";
import { MobileBreakpoint } from "../config/constants";
import PropTypes from "prop-types";

const FooterProps = {
  style: PropTypes.object,
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${({ style }) => style && css(style)}
`;

const TopFooter = styled.div`
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 20px;
  align-items: flex-start;
  max-width: 1600px;
  width: 100%;

  @media screen and (max-width: ${MobileBreakpoint}) {
    padding: 5px;
  }
`;

const BottomFooter = styled.div`
  background-color: #6fada8;
  padding: 10px 20px;
  text-align: center;
  color: white;

  @media screen and (max-width: ${MobileBreakpoint}) {
    padding: 5px;
  }
`;

function Footer({ style }) {
  return (
    <FooterContainer style={style}>
      <TopFooter>
        <p style={{ textAlign: "center" }}>
          This is NOT an actual retail web store; it is intended solely for demonstration purposes
        </p>
      </TopFooter>
      <BottomFooter>
        <span>Copyright &copy; {new Date().getFullYear()} Authentik Adventures. All Rights Reserved.</span>
      </BottomFooter>
    </FooterContainer>
  );
}

Footer.propTypes = FooterProps;

export default Footer;
