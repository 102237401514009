import React from 'react';
import styled from 'styled-components';

const HeaderNavigationContainer = styled.div`
    background-color: #f0f0f0;
    padding: 0;
`;

const HeaderNavigationInner = styled.div`
    margin: 0 auto;
    color: #575757;
    max-width: 1600px;
    padding: 1rem 20px;
    height: 51px;
    width: 100%;
    display: flex;
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: center;

    p {
        margin: auto 0 auto auto;
        color: inherit;
    }
`;

const HeaderNavigation = () => {
    return (
        <HeaderNavigationContainer>
            <HeaderNavigationInner>
                <p style={{ fontSize: 10 }}>
                    This is NOT an actual retail web store; it is intended
                    solely for demonstration purposes
                </p>
            </HeaderNavigationInner>
        </HeaderNavigationContainer>
    );
};

export default HeaderNavigation;

