export const HamburgerIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.109" height="25.832" viewBox="0 0 33.109 25.832" {...props}>
      <path
        d="M0,24.832V21.554H33.109v3.278ZM0,10.578H33.109v3.327H0ZM0,0H33.109V3.278H0Z"
        transform="translate(0 1)"
        fill="#171717"
      />
    </svg>
  );
};
