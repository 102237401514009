import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CartItemProps = {
  children: PropTypes.node,
};

const CartItemWrapper = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const CartItem = props => {
  return <CartItemWrapper>{props.children}</CartItemWrapper>;
};

CartItem.propTypes = CartItemProps;

export default CartItem;
