import { States } from "countries-states-cities-service";

const cityStateCheck = str => /^[, a-zA-Z]+$/.test(str);
const postalCodeCheck = str => /^\d{5}$/.test(str);

let states = States.getStates({
  filters: {
    country_code: "US",
  },
});

export const AddressChecker = address => {
  let addressComponents;
  let cityState;

  if (cityStateCheck(address)) {
    addressComponents = address.split(",").map(component => component.replace(/,$/, "").trim());
  } else if (postalCodeCheck(address)) {
    addressComponents = address;
  } else {
    addressComponents = address.split(/ (?![^,]*,)/).map(component => component.replace(/,$/, "").trim());
  }

  if (postalCodeCheck(address)) {
    if (/^\d{5}$/.test(addressComponents)) {
      return { zip: addressComponents };
    }
  } else if (cityStateCheck(address)) {
    if (addressComponents.length >= 2) {
      states.forEach(state => {
        const matchingCities = Object.values(state).filter(it => {
          cityState = {
            city: addressComponents[0],
            state: addressComponents[1],
          };

          return new RegExp(`\\b${addressComponents[1]}\\b`, "i").test(it);
        });

        if (matchingCities.length > 0) {
          // Use the matching city/state found
          cityState = {
            city: addressComponents[0],
            state: addressComponents[1],
          };
        }
      });
    }

    return cityState;
  } else {
    return { zip: "", city: "", state: "" };
  }
};

export const capitalizeWords = str => {
  // Split the string into words, capitalize the first letter of each word, and join them back
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
