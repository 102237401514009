import { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CloseIcon from "../icons/Close";
import Button from "../Button/Button";

const PopperProps = {
  arrow: PropTypes.bool,
  menuPopper: PropTypes.bool,
  closeButton: PropTypes.bool,
  handleClose: PropTypes.func,
  arrowPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  variant: PropTypes.oneOf(["hover", "click"]),
  children: PropTypes.node.isRequired,
};

const MenuPopperStyled = styled.div`
  position: absolute;
  z-index: 1;

  ul {
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
    border: 1px solid #ccc;
    min-width: 230px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  li {
    position: relative;

    ul {
      position: absolute;
      top: 0;
      left: 100%;
      display: none;
    }

    &:hover ul {
      display: block;
    }
  }
`;

const PopperStyled = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
  border: 1px solid #ccc;
  min-width: 230px;
  max-width: 500px;
  width: 100%;
`;

const PopperContentStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const PopperHeaderStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 0 2rem;
`;

const PopperHeaderProps = {
  closeButton: PropTypes.bool,
  handleClose: PropTypes.func,
};

const PopperHeader = ({ closeButton, handleClose }) => {
  if (closeButton) {
    return (
      <PopperHeaderStyled>
        <Button iconButton>
          <CloseIcon onClick={handleClose} sx={{ width: 18 }} />
        </Button>
      </PopperHeaderStyled>
    );
  }
};

PopperHeader.propTypes = PopperHeaderProps;

const Popper = forwardRef(
  (
    {
      arrow = false,
      menuPopper = true,
      closeButton = false,
      handleClose,
      arrowPosition = "top",
      variant = "hover",
      children,
      ...props
    },
    ref
  ) => {
    if (menuPopper) {
      return (
        <MenuPopperStyled ref={ref} {...props}>
          {children}
        </MenuPopperStyled>
      );
    }

    if (!menuPopper) {
      return (
        <PopperStyled ref={ref} {...props}>
          <PopperHeader closeButton={closeButton} handleClose={handleClose} />
          <PopperContentStyled>{children}</PopperContentStyled>
        </PopperStyled>
      );
    }
  }
);

Popper.propTypes = PopperProps;

export default Popper;
